/**
 * @name RsWizard.js
 * @author Mike Fitzbaxter
 * @fileoverview Remote Social form wizard component
 * A Remote Social form interface that uses a Wizard style flow to collect data
 * in multiple steps from the user. Steps are provided to the wizard along with
 * any initial values used in each step and a validation function for each step.
 * Navigation between steps or back out of the form is handled by the wizard.
 * Each step is responsible for providing:
 *  - a label for the step
 *  - an object of initialValues for each field in the step
 *  - a validation function for each field in the step
 *  - a React Component that renders the form for the step
 *
 * The wizard will:
 *  - render an overall Form title
 *  - render a progress Stepper component for each step
 *  - render the form component for the current step
 *  - render navigation buttons to move forwards/backwards between steps
 *  - submit all the data to the onSubmit callback when the final step is completed
 *  - cancel the form and return to the previous page when the cancel button is clicked
 *
 *  The navigation buttons will only become enabled if the current step is valid
 *  - this uses the step's validation function to determine if the step is valid
 *  - this will also block movement forward if the form is untouched
 *  - there is more investigation to do if we want the form to understand whether
 *    certain fields are required or not and then appropriately update the button
 *    state based on that.
 */

/**
 * @typedef {Object} FormikBag
 * @property {Function} resetForm - reset the form to the initial values
 * @property {Function} setErrors - set the form errors
 * @property {Function} setFieldError - set a specific field error
 * @property {Function} setFieldTouched - set a specific field as touched
 * @property {Function} setFieldValue - set a specific field value
 * @property {Function} setFormikState - set the formik state
 * @property {Function} setStatus - set the form status
 * @property {Function} setSubmitting - set the form submitting state
 * @property {Function} setTouched - set the form touched state
 * @property {Function} setValues - set the form values
 * @property {Function} submitForm - submit the form
 * @property {Function} validateForm - validate the form
 * @property {Function} validateField - validate a specific field
 */

/**
 * submit callback used in the wizard
 * is only called if there are no validation errors
 * https://formik.org/docs/api/formik#onsubmit-values-values-formikbag-formikbag--void--promiseany
 * @callback onSubmit
 * @param {Object} values - form values to submit
 * @param {Object} formikBag - formikBag of methods to update the form
 */

/**
 * cancel callback used in the wizard - called when the user cancels the form
 * @callback onCancel
 */

/**
 * validate callback used in the wizard to validate all form values
 * @callback checkStepValid
 * @param {Object} values - form values to validate
 * @return {Boolean} true if the step is valid, false otherwise
 */

/**
 * validate callback used within Formik to validate the entire form
 * we probable don't use this callback as the multi-part form will validate
 * each step individually rather than all at once using this callback
 * @callback validate
 * @param {Object} values - form values to validate
 * @return {Object} object of errors for each field
 */

import { useState, useEffect, useCallback, useMemo, useReducer } from 'react';
import { Formik } from 'formik';
import {
	Typography,
	Divider,
	Stepper,
	Step,
	StepLabel,
	Button,
	Box,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingButton } from '@common/components';
import Joi from 'joi';

/**
 * Create a formik bag from formikProps - to be used in the wizard
 * during handleNextStep and handlePrevStep callbacks to allow modifying of
 * the formik state if required
 * @param {Object} formikProps
 * @return {Object} formikBag
 */
// const makeFormikBag = (formikProps) => ({
// 	resetForm: formikProps.resetForm,
// 	setErrors: formikProps.setErrors,
// 	setFieldError: formikProps.setFieldError,
// 	setFieldTouched: formikProps.setFieldTouched,
// 	setFieldValue: formikProps.setFieldValue,
// 	setFormikState: formikProps.setFormikState,
// 	setStatus: formikProps.setStatus,
// 	setSubmitting: formikProps.setSubmitting,
// 	setTouched: formikProps.setTouched,
// 	setValues: formikProps.setValues,
// 	submitForm: formikProps.submitForm,
// 	validateForm: formikProps.validateForm,
// 	validateField: formikProps.validateField,
// });

/** title for the form wizard */
const WizardTitle = ({ title }) => (
	<>
		<Typography
			variant="h1"
			color="primary"
			sx={{ mb: 4, textAlign: 'center' }}
		>
			{title}
		</Typography>
		<Divider />
	</>
);

/**
 * navigation component for the form wizard
 * This component renders it's elements backwards so that tabIndex jumps to
 * the submit option before jumping to the back option, hence the 'row-reverse'
 * flexDirection declaration.
 */
const WizardNav = ({
	handleReset,
	handleNextStep,
	handlePrevStep,
	stepState,
	isSubmitting,
	status,
}) => {
	useEffect(() => {
		// console.log('isSubmitting', isSubmitting);
	}, [isSubmitting]);

	useEffect(() => {
		// console.log('stepState', stepState);
	}, [stepState]);

	return (
		<Grid
			container
			xxs={12}
			sx={{ flexDirection: 'row-reverse' }}
			spacing={4}
		>
			<Grid xxs="auto">
				{status?.state === 'error' ? (
					<LoadingButton
						variant="contained"
						type="button"
						loading={isSubmitting}
						endIcon={<ChevronRight />}
						onClick={handleReset}
						color="warning"
					>
						Reset form
					</LoadingButton>
				) : (
					<LoadingButton
						variant="contained"
						type="button"
						tabIndex={0}
						loading={isSubmitting}
						endIcon={<ChevronRight />}
						onClick={handleNextStep}
						disabled={
							stepState.invalid || // step is not valid
							isSubmitting // form is submitting
						}
					>
						{stepState.lastStep ? 'Submit' : 'Next'}
					</LoadingButton>
				)}
			</Grid>
			<Grid xxs="auto">
				<Button onClick={handlePrevStep}>
					{stepState.firstStep ? 'Cancel' : 'Back'}
				</Button>
			</Grid>
			<Grid xxs>
				{status?.state === 'error' && (
					<Box sx={{ textAlign: 'right', color: 'error' }}>
						<Typography
							color="error"
							sx={{ lineHeight: '30.4px', p: '6px' }}
						>
							<b>Error</b>: {status.message}
						</Typography>
					</Box>
				)}
				{status?.state === 'inProgress' && (
					<Box sx={{ textAlign: 'right' }}>
						<Typography
							color="success"
							sx={{ lineHeight: '30.4px', p: '6px' }}
						>
							Submitting
						</Typography>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};

/**
 * Renders the current step using the component provided by the step
 * and passes along all props from the wizard
 */
const CurrentStep = ({ Component, handleNextStep, ...props }) => {
	// log('CurrentStep render');
	return (
		<form
			onSubmit={handleNextStep}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					// Keeps form from submitting on hitting enter
					e.preventDefault();
					handleNextStep();
				}
			}}
		>
			<Component {...props} />
		</form>
	);
};

/**
 * Internal component for the wizard
 * used to add formikProps to the other passed in props
 * @param {string} title - title for the wizard
 * @param {Array.<Step>} steps - array of steps for the wizard
 * @param {Object.<string, any>} formikProps - props from Formik
 *   @param {Any} formikProps.status - form status passed to setStatus
 *   @param {Object} formikProps.values - form values
 *   @param {Object} formikProps.errors - form errors
 *   @param {Object} formikProps.touched - form touched
 *   @param {Object} formikProps.dirty - form dirty
 *   @param {Function} formikProps.handleSubmit - form submit handler
 * 	 @param {Function} formikProps.handleReset - form reset handler
 *   @param {Boolean} formikProps.isSubmitting - form isSubmitting
 *   @param {Boolean} formikProps.isValid - form isValid
 * 	 @param {Boolean} formikProps.isValidating - validation in progress
 *   @param {number} formikProps.submitCount - number of times the form has been submitted
 *   @param {Function} formikProps.resetForm - reset the form to initial values
 *   @param {Function} formikProps.setErrors - ({ field: errorMsg, ...})
 *   @param {Function} formikProps.setFieldError - (field, errorMsg)
 *   @param {Function} formikProps.setTouched - ({ field: boolean, ...})
 *   @param {Function} formikProps.setFieldTouched - (field, boolean)
 *   @param {Function} formikProps.setValues - ({ field: value, ...})
 *   @param {Function} formikProps.setFieldValue - (field, value)
 *   @param {Function} formikProps.setSubmitting - (boolean)
 * 	 @param {Function} formikProps.setStatus - (any)
 *   @param {Function} formikProps.validate - validate the form
 */
const WizardInternal = ({ title, steps, formikProps }) => {
	const stepInitialState = {
		invalid: true,
		firstStep: true,
		lastStep: false,
	};
	const stepReducer = (state, action) => {
		switch (action.type) {
			case 'firstStep':
				return { ...state, firstStep: true, lastStep: false };
			case 'midStep':
				return { ...state, firstStep: false, lastStep: false };
			case 'lastStep':
				return { ...state, firstStep: false, lastStep: true };
			case 'valid':
				return { ...state, invalid: false };
			case 'invalid':
				return { ...state, invalid: true };
			default:
				return state;
		}
	};
	const [currentStep, setCurrentStep] = useState(0);
	const [stepState, stepDispatch] = useReducer(stepReducer, stepInitialState);

	const {
		values,
		errors,
		dirty,
		touched,
		submitForm, // trigger onSubmit callback
		resetForm, // trigger onReset callback
		isSubmitting,
		status,
	} = formikProps;

	// log('🧙‍♂️ WizardInternal', {
	// 	values: { ...values },
	// 	errors: { ...errors },
	// 	steps: { ...steps },
	// 	currentStep,
	// 	stepInvalid,
	// 	isLastStep,
	// 	isSubmitting,
	// });

	/** reset the form and return to first step */
	const handleReset = () => {
		resetForm();
		setCurrentStep(0);
	};

	/** handle clicking the next step button */
	const handleNextStep = () => {
		if (stepState.invalid) return; // fail on stepInvalid
		if (stepState.lastStep) return submitForm(); // submit on lastStep
		return setCurrentStep((prevStep) => prevStep + 1); // increment
	};

	/** handle clicking the previous step button */
	const handlePrevStep = () => {
		if (stepState.firstStep) return handleReset();
		return setCurrentStep((prevStep) => prevStep - 1);
	};

	/** update the stepState based on the value of currentStep */
	useEffect(() => {
		if (currentStep < 0) return setCurrentStep(0);
		if (currentStep === 0) return stepDispatch({ type: 'firstStep' });
		if (currentStep >= steps.length - 1) {
			return stepDispatch({ type: 'lastStep' });
		}
		return stepDispatch({ type: 'midStep' });
	}, [currentStep, steps]);

	/** Checks if the step is valid on mount and on every change */
	useEffect(() => {
		// log('checking if currentStep is valid', { ...values }, { ...errors });
		const stepFields = Object.keys(steps[currentStep].initialValues);
		const stepFieldsValues =
			values && stepFields.every((key) => values[key]);
		const stepFieldsErrors =
			errors && stepFields.some((key) => errors[key]);
		if (!stepFieldsErrors && stepFieldsValues) {
			stepDispatch({ type: 'valid' });
		} else {
			stepDispatch({ type: 'invalid' });
		}
	}, [steps, currentStep, values, errors]);

	return (
		<>
			<Grid container xs={12} spacing={1}>
				{title && (
					<Grid xs={12}>
						<WizardTitle title={title} />
					</Grid>
				)}
				<Grid xs={12}>
					<Stepper
						activeStep={currentStep}
						alternativeLabel
						sx={{ my: 8 }}
					>
						{steps.map((step, index) => (
							<Step key={index}>
								<StepLabel>{step.label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Grid>
				<Grid xs={12}>
					<CurrentStep
						Component={steps[currentStep].Component}
						handleNextStep={handleNextStep}
						{...formikProps}
					/>
				</Grid>
				<Grid xs={12}>
					<WizardNav
						handleReset={handleReset}
						handleNextStep={handleNextStep}
						handlePrevStep={handlePrevStep}
						stepState={stepState}
						submitForm={submitForm}
						isSubmitting={isSubmitting}
						dirty={dirty}
						touched={touched}
						status={status}
					/>
				</Grid>
			</Grid>
		</>
	);
};

/**
 * @typedef {Object} Step
 * @property {string} label - label for the step
 * @property {Object} initialValues - initial values for the step
 * @property {Object} validate - validation schema for the step
 * @property {React.Component} Component - form component for the step
 */

/**
 * onSubmit callback for the Wizard
 * @callback onSubmit
 * @param {values} values - form values
 * @param {formikBag} formikBag - formik bag
 * @return {Promise} - promise
 */

/**
 * onReset callback for the Wizard
 * @callback onReset
 * @param {values} values - form values
 * @param {formikBag} formikBag - formik bag
 * @return {Promise} - promise
 */

/**
 * reusable react component that will create a multi-part wizard
 * from the supplied components and metadata.
 * Uses Formik for form state.
 * @param {string} title - title for the wizard form
 * @param {Array.<Step>} steps  - Array of step objects for the wizard
 * 	 @param {string} Step.label - label/title for the step
 * 	 @param {Object} Step.initialValues - initial values for the step
 * 	 @param {Object} Step.validate - validation schema for the step
 *   @param {Function} Step.Component - react component to render for the step
 * @param {Object.<any>} formikConfig - config for the Formik component
 *   @param {Object} [formikConfig.initialErrors={}] - initial errors for the form
 *   @param {Object} [formikConfig.initialTouched={}] - initial touched for the form
 *   @param {Boolean} [formikConfig.validateOnBlur=false] - Boolean, whether to validate on blur
 *   @param {Boolean} [formikConfig.validateOnChange=false] - Boolean, whether to validate on change
 *   @param {Boolean} [formikConfig.validateOnMount=false] - Boolean, whether to validate on mount
 *   @param {Boolean} [formikConfig.enableReinitialize=false] - Boolean, whether to reinitialize the form when initialValues change
 *   @param {Boolean} [formikConfig.isInitialValid=false] - Boolean, whether the form is initially valid
 *   @param {onSubmit} [formikConfig.onSubmit=() => {}] - callback for when the form is submitted
 *   @param {onReset} [formikConfig.onReset=() => {}] - callback for when the form is reset
 */
export const RsWizard = ({ title, steps, formikConfig }) => {
	/** compile all the supplied step initialValues into a single object */
	const initialValues = useMemo(() => {
		return steps.reduce(
			(acc, step) => ({ ...acc, ...step.initialValues }),
			{},
		);
	}, [steps]);

	const combinedSchema = useMemo(() => {
		// log('🧙‍♂️ RsWizard initialValues:', initialValues);
		const combinedValidations = steps.reduce(
			(acc, step) => ({ ...acc, ...step.validate }),
			{},
		);
		return Joi.object({ ...combinedValidations });
	}, [steps]);

	/**
	 * custom validation function that combines all the supplied step
	 * validations into one validation schema and then runs the schema to
	 * validate the values
	 */
	const validate = useCallback(
		(values) => {
			// log('🧙‍♂️ RsWizard - validate - values:', { ...values });
			const errors = {};
			const { error } = combinedSchema.validate(values, {
				abortEarly: false,
			});

			if (error) {
				error.details.forEach((detail) => {
					errors[detail.path[0]] = detail.message;
				});
				// log('🧙‍♂️ RsWizard - validate - ❌ errors:', { ...errors });
			} else {
				// log('🧙‍♂️ RsWizard - validate - ✅ no errors!');
			}
			return errors;
		},
		[combinedSchema],
	);

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			{...formikConfig}
		>
			{({ ...formikProps }) => {
				return (
					<WizardInternal
						title={title}
						steps={steps}
						formikProps={formikProps}
					/>
				);
			}}
		</Formik>
	);
};
