import { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

const getTextFieldStyles = (color, errorColor, sx) => ({
	'mt': 3,
	'display': 'flex',
	'flexGrow': 1,
	'.MuiTextField-root': {
		display: 'flex',
		flexGrow: 1,
		background: 'red',
	},
	'.MuiOutlinedInput-root': {
		'color': `${color.containerText}cc`, // make semi-transparent
		'background': `${color.surface}77`, // make semi-transparent
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: `${color.containerText}6f`, // make semi-transparent
			borderWidth: '1px',
		},
		'input': {
			borderRadius: 'inherit',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			pl: 3,
			flexGrow: 1,
		},
		'.MuiInputAdornment-root': {},
		'.MuiInputAdornment-root, .MuiIconButton-root': {
			color: 'inherit',
		},
		'&:hover': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}99`, // make semi-transparent
				borderWidth: '1.5px',
			},
		},
		'&.Mui-focused': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}cc`, // make semi-transparent
				borderWidth: `2.5px`,
			},
		},
		'&.Mui-disabled': {
			'color': `${color.containerText}99`, // make semi-transparent
			'background': `${color.container}aa`, // make semi-transparent
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}55`, // make semi-transparent
			},
			'.MuiOutlinedInput-input, input:disabled': {
				TextFillColor: 'inherit',
				WebkitTextFillColor: 'inherit',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}55`, // make semi-transparent
				borderWidth: '1px',
			},
		},
		'&.Mui-error': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${errorColor.main}cc`, // make semi-transparent
				borderWidth: '2px',
			},
		},
	},
	'.MuiFormHelperText-root': {
		'fontWeight': 'bold',
		'color': `${color.contrastText}`,
		'p': 2,
		'mx': 1,
		'my': 0,
		'fontSize': '0.8rem',
		'borderRadius': '0 0 0.25rem 0.25rem',
		'background': `${color.main}cc`, // make semi-transparent
		'&.Mui-error': {
			background: `${errorColor.main}cc`, // make semi-transparent
			color: `${errorColor.contrastText}`,
		},
	},
	...sx,
});

export const DateInternal = ({
	/** props required for the wrapper components (intro, caption...) */
	intro,
	caption,
	sx,
	/** props required for the textField */
	color = 'primary',
	tabIndex,
	helperText,
	placeholder,
	error,
	/** remainder props passed to the main component */
	field,
	form,
	...props
}) => {
	const theme = useTheme();
	const themeColor = theme.palette[color];
	const themeErrorColor = theme.palette.error;
	// log('DateInternal - render - props:', field, form, props);

	/**
	 * values inside the DatePicker need to always been in Luxon DateTime
	 * format and as a result we capture the value from the DatePicker onChange
	 * and convert it to an ISO string before passing the value up to parent
	 */
	const handleChange = (value) => {
		// log('DateInternal handleChange - value:', value.toISO());
		if (props.onChange) {
			props.onChange(value.toUTC().toISO());
		} else {
			field.onChange(value.toUTC().toISO());
		}
	};

	/**
	 * similarly, values passed down to this component from the parent
	 * validation/initialValues are always in ISO format and need to be
	 * converted to a Luxon DateTime object before passing to the DatePicker
	 */
	// log('field.value:', field.value);
	const dateValue = DateTime.fromISO(field?.value || props?.value || '');
	// log('dateValue', dateValue);

	return (
		<Box sx={sx}>
			{intro && (
				<Typography variant="h4" color={color}>
					{intro}
				</Typography>
			)}
			<DatePicker
				{...field}
				{...props}
				onChange={handleChange}
				slotProps={{
					textField: {
						tabIndex,
						helperText,
						placeholder,
						error,
						sx: getTextFieldStyles(themeColor, themeErrorColor),
					},
				}}
				value={dateValue}
			/>
			{caption && <Typography variant="caption">{caption}</Typography>}
		</Box>
	);
};

export const Date = forwardRef(({ ...props }, ref) => {
	// log('Date - render - props:', props);
	return <DateInternal inputRef={ref} {...props} />;
});
