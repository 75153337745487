import { forwardRef } from 'react';
import { Box, Typography, Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const getTextFieldStyles = (color, errorColor, sx) => ({
	'mt': 3,
	'.MuiOutlinedInput-root': {
		'color': `${color.containerText}cc`, // make semi-transparent
		'background': `${color.surface}77`, // make semi-transparent
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: `${color.containerText}6f`, // make semi-transparent
			borderWidth: '1px',
		},
		'input': {
			borderRadius: 'inherit',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			pl: 3,
		},
		'.MuiInputAdornment-root, .MuiIconButton-root': {
			color: 'inherit',
		},
		'&:hover': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}99`, // make semi-transparent
				borderWidth: '1.5px',
			},
		},
		'&.Mui-focused': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}cc`, // make semi-transparent
				borderWidth: `2.5px`,
			},
		},
		'&.Mui-disabled': {
			'color': `${color.containerText}99`, // make semi-transparent
			'background': `${color.container}aa`, // make semi-transparent
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}55`, // make semi-transparent
			},
			'.MuiOutlinedInput-input, input:disabled': {
				TextFillColor: 'inherit',
				WebkitTextFillColor: 'inherit',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}55`, // make semi-transparent
				borderWidth: '1px',
			},
		},
		'&.Mui-error': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${errorColor.main}cc`, // make semi-transparent
				borderWidth: '2px',
			},
		},
	},
	'.MuiFormHelperText-root': {
		'fontWeight': 'bold',
		'color': `${color.contrastText}`,
		'p': 2,
		'mx': 1,
		'my': 0,
		'fontSize': '0.8rem',
		'borderRadius': '0 0 0.25rem 0.25rem',
		'background': `${color.main}cc`, // make semi-transparent
		'&.Mui-error': {
			background: `${errorColor.main}cc`, // make semi-transparent
			color: `${errorColor.contrastText}`,
		},
	},
	...sx,
});

const ComboBox = ({
	inputRef,
	color = 'primary',
	field,
	form,
	/** TextField props that we pull out */
	label,
	tabIndex,
	placeholder,
	error,
	helperText,
	InputProps,
	/** remaining props passed to the AutoComplete */
	options,
	...props
}) => {
	// log('ComboBox - render props:', field, props);

	const handleChange = (e, value) => {
		// log('Combo - handleChange - value:', value);
		if (value && value.inputValue) {
			if (value.inputValue.startsWith('#')) {
				value.inputValue = value.inputValue.slice(1);
			}
			form.setFieldValue(field.name, value.inputValue);
		} else {
			if (value && value.startsWith('#')) {
				value = value.slice(1);
			}
			form.setFieldValue(field.name, value);
		}
	};

	const theme = useTheme();
	const themeColor = theme.palette[color];
	const errorColor = color.endsWith('Dark') // if the provided palette ends with 'Dark'
		? theme.palette.errorDark // use the dark error palette
		: theme.palette.error; // otherwise use the regular error palette

	const renderInput = ({ ...params }) => {
		return (
			<TextField
				{...params}
				inputProps={{
					tabIndex, // pass the tabIndex through to the input
					// name: field.name, // pass the field name through to the input
					...params.inputProps,
				}}
				InputProps={{
					...params.InputProps,
					...InputProps,
				}}
				label={label}
				placeholder={placeholder}
				error={error}
				helperText={helperText}
				sx={getTextFieldStyles(themeColor, errorColor)}
			/>
		);
	};

	return (
		<Box sx={props.sx}>
			{props.intro && (
				<Typography variant="h4" color={color}>
					{props.intro}
				</Typography>
			)}
			<Autocomplete
				ref={inputRef}
				options={options}
				value={field.value}
				name={field.name}
				onChange={handleChange}
				getOptionLabel={(option) => {
					if (option.inputValue) {
						return option.inputValue;
					}
					return option;
				}}
				freeSolo={props.freeSolo === undefined ? true : props.freeSolo}
				autoSelect={
					props.autoSelect === undefined ? true : props.autoSelect
				}
				selectOnFocus={
					props.selectOnFocus === undefined
						? true
						: props.selectOnFocus
				}
				clearOnBlur={
					props.clearOnBlur === undefined ? true : props.clearOnBlur
				}
				renderInput={renderInput}
			/>
			{props.caption && (
				<Typography variant="caption">{props.caption}</Typography>
			)}
		</Box>
	);
};

/**
 * A combo (Autocomplete) box that can be used with Formik.
 *
 * The Formik Field component should be used as the parent component like so:
 *
 * ```
 * <Field
 * 	component={Combo}
 * 	name="myField"
 * 	inputRef={ref} // if you need to access a ref to the input
 * 	label="My Field" // label for the input component
 * 	placeholder="My Placeholder" // placeholder for the input component
 * 	intro="My Intro" // optional intro text to display above the input
 * 	caption="My Caption" // optional caption text to display below the input
 * 	error={touched.myField && !!errors.myField} // Formik error state boolean
 * 	helperText={touched.myField && errors.myField} // Formik error message
 * />
 * ```
 * See the material-ui Autocomplete component docs for more props that can be
 * passed in to customize the input behavior
 *
 * @link https://mui.com/material-ui/react-autocomplete/
 * @link https://mui.com/material-ui/react-text-field/
 * @link https://formik.org/docs/api/field
 */
export const Combo = forwardRef(({ ...props }, ref) => {
	return <ComboBox {...props} inputRef={ref} />;
});
