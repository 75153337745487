import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { firestore } from '@common/firebase';
import { RsUserAvatar, RsOrgAvatar, Loading } from '@common/components';
import { useCurrentUser, useRsNavigate, useOrgs } from '@common/hooks';
// import { makeModeTheme } from '@common/themes';
import { RsLogoType } from '@common/assets';
import { createEncodedContinueUrl } from '@common/utils';

import { useTheme } from '@mui/material/styles';
import {
	Box,
	Link,
	AppBar,
	Avatar,
	Toolbar,
	Button,
	IconButton,
	Popper,
	MenuList,
	MenuItem,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItemAvatar,
	Divider,
	Typography,
	ClickAwayListener,
	Dialog,
	DialogTitle,
	useMediaQuery,
} from '@mui/material';
import {
	Logout,
	ChangeCircle,
	Lock,
	Close,
	Menu,
	AddBox,
} from '@mui/icons-material';

// custom hook that returns a user's org documents from Firestore
// this is a bespoke query as we are querying using "in" and need to batch
// the queries into groups of 10 orgIds at a time.
const useUserOrgDocs = (user) => {
	const [firestoreData, setFirestoreData] = useState({
		loading: false,
		status: 'idle',
		error: false,
		data: false,
	});
	const batchSize = 10;
	const userOrgDocs = useRef([]);

	// trigger the fetching of all the orgDocs
	const fetch = useCallback(async (orgIdsArray) => {
		try {
			setFirestoreData({
				loading: true,
				status: 'inProgress',
				error: false,
				data: false,
			});
			// reset the previously fetched data
			userOrgDocs.current = [];

			const orgsCollectionRef = collection(firestore, 'orgs');
			// while there are still id's to fetch
			while (orgIdsArray.length) {
				// remove the first 10 id's from the array
				const batchOrgs = orgIdsArray.splice(0, batchSize);
				const q = query(
					orgsCollectionRef,
					where('id', 'in', batchOrgs),
				);
				const batchSnapshot = await getDocs(q);
				batchSnapshot.forEach((doc) => {
					userOrgDocs.current.push(doc.data());
				});
			}
			setFirestoreData({
				loading: false,
				status: 'complete',
				error: false,
				data: userOrgDocs.current,
			});
		} catch (e) {
			console.error('useUserOrgsDocs error', e.message);
			setFirestoreData({
				loading: false,
				status: 'error',
				error: e,
				data: false,
			});
		}
	}, []);

	const value = useMemo(
		() => ({
			...firestoreData,
			fetch,
		}),
		[firestoreData, fetch],
	);

	return value;
};

// user is logged out, not logged in
const LoggedOutMenu = () => {
	const { navigateToApp } = useRsNavigate();
	const handleLogin = () => {
		const continueUrl = `?continueUrl=${createEncodedContinueUrl()}`;
		navigateToApp('auth', continueUrl, { replace: true });
	};

	return <Button onClick={handleLogin}>Log&nbsp;in or Register</Button>;
};

const SwitchDialog = ({ user, switchOpen, onClose, handleCreateNewOrg }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const userOrgDocs = useUserOrgDocs();
	const { setCurrentOrg } = useOrgs();
	const [loadingOrg, setLoadingOrg] = useState(false);

	const handleClose = () => {
		onClose();
	};

	const handleSelectItem = async (orgId) => {
		if (orgId !== user.metadata.currentOrgId) {
			try {
				setLoadingOrg(true);
				await setCurrentOrg(orgId);
				setLoadingOrg(false);
				handleClose();
			} catch (e) {
				console.error('setCurrentOrg', e.message, e);
				setLoadingOrg(false);
				handleClose();
			}
		}
	};

	// fetch the orgs doc for each of the orgs on the users metadata object
	// doing this in the below way will result in a non-realtime snapshot
	// of the orgs data at the time the docs are fetched. If the user is
	// added/removed from an org whilst viewing this list, the data will NOT
	// change to reflect that. This could lead to some unexpected behaviour
	// when a user tries to switch into an org that they are not
	// allowed to view
	useEffect(() => {
		if (switchOpen) {
			const userOrgIds = Object.entries(user.metadata?.orgs).reduce(
				(prev, current) => {
					prev.push(current[0]);
					return prev;
				},
				[],
			);
			userOrgDocs.fetch(userOrgIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.metadata?.orgs, switchOpen]);

	// useEffect(() => {
	// 	// log('userOrgDocs', userOrgDocs);
	// }, [userOrgDocs]);

	return (
		<Dialog
			onClose={handleClose}
			open={switchOpen}
			maxWidth="xs"
			fullWidth
			fullScreen={fullScreen}
			sx={{
				py: 8,
			}}
		>
			<DialogTitle
				sx={{
					borderBottom: `1px solid ${theme.palette.neutral.container}`,
					fontWeight: 'bold',
				}}
			>
				Switch Org
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
				) : null}
			</DialogTitle>
			{loadingOrg ? (
				<Box sx={{ m: 8 }}>
					<Loading
						color={theme.palette.primary.main}
						label="Switching Org"
						showRandom={false}
					/>
				</Box>
			) : userOrgDocs.status !== 'complete' ? (
				<Box sx={{ m: 8 }}>
					<Loading
						color={theme.palette.primary.main}
						label="Fetching Orgs"
						showRandom={false}
					/>
				</Box>
			) : (
				<List>
					{userOrgDocs.data &&
						userOrgDocs.data.map((org) => (
							<ListItemButton
								key={org.id}
								button
								onClick={() => {
									handleSelectItem(org.id);
								}}
								selected={user.metadata.currentOrgId === org.id}
							>
								<ListItemIcon>
									<RsOrgAvatar org={org} />
								</ListItemIcon>
								<ListItemText
									primary={org.name}
									secondary={user.metadata.orgs[org.id].role}
								/>
							</ListItemButton>
						))}
					{userOrgDocs.data && <Divider />}
					<ListItem button onClick={handleCreateNewOrg}>
						<ListItemAvatar>
							<Avatar
								sx={{
									background: theme.palette.primary.container,
								}}
							>
								<AddBox />
							</Avatar>
						</ListItemAvatar>
						<ListItemText>Create new org</ListItemText>
					</ListItem>
				</List>
			)}
		</Dialog>
	);
};

// user is logged in
const LoggedInMenu = ({ user, theme }) => {
	const anchorRef = useRef();
	const { navigateToApp } = useRsNavigate();
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [switchOpen, setSwitchOpen] = useState(false);

	const userOrgs = user.metadata?.orgs || {};

	// const userMenuStyles = {
	// 	'.MuiMenuItem-root': {
	// 		'.MuiTypography-body1': {
	// 			m: 0,
	// 		},
	// 	},
	// };

	const toggleUserMenu = () => {
		setUserMenuOpen((prev) => !prev);
	};

	const handleUserMenuClose = () => {
		setUserMenuOpen(false);
	};

	const handleLogout = () => {
		handleUserMenuClose();
		navigateToApp('auth', '/logout');
	};

	const handleProfile = () => {
		log('handle profile goes here...');
		handleUserMenuClose();
		return;
	};

	const handleSwitchOpen = () => {
		handleUserMenuClose();
		setSwitchOpen(true);
		return;
	};

	const handleSwitchClose = async (selectedOrg) => {
		setSwitchOpen(false);
	};

	const handleLoginSettings = () => {
		log('Handle Login Settings here...');
		handleUserMenuClose();
		return;
	};

	const handleCreateNewOrg = () => {
		log('Create new org here...');
		navigateToApp('app', '/create');
		handleUserMenuClose();
	};

	const { currentOrg } = user || {};

	/** menu component that displays username and org details (if relevant) */
	const LoggedInMenuTopper = () => {
		return (
			<MenuItem
				onClick={handleProfile}
				sx={{
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',
				}}
			>
				<Typography noWrap sx={{ fontWeight: 'bold' }}>
					{user.displayName}
				</Typography>
				{currentOrg?.user?.profile?.org?.title && (
					<Typography
						variant="caption"
						sx={{ display: 'block', pb: 2 }}
					>
						{currentOrg?.user?.profile?.org?.title}
					</Typography>
				)}
				{currentOrg?.name && (
					<RsOrgAvatar
						org={currentOrg}
						title={currentOrg.name}
						variant="vertical"
					/>
				)}
			</MenuItem>
		);
	};

	/** menu components that show options for working with orgs (if relevant) */
	const LoggedInWithOrgsMenu = () => {
		const numOrgs = Object.entries(userOrgs).length;

		return (
			<>
				<MenuItem onClick={handleProfile} disabled>
					<ListItemIcon>
						<RsUserAvatar user={user} size="1.25rem" />
					</ListItemIcon>
					<Typography noWrap>
						{user.displayName}'s Org Profile
					</Typography>
				</MenuItem>
				{numOrgs > 1 && (
					<MenuItem onClick={handleSwitchOpen}>
						<ListItemIcon>
							<ChangeCircle fontSize="small" color="primary" />
						</ListItemIcon>
						<Typography noWrap>Switch Organisation</Typography>
					</MenuItem>
				)}
			</>
		);
	};

	/** menu components that show without orgs present */
	const CreateNewOrgMenu = () => {
		return (
			<MenuItem onClick={handleCreateNewOrg} disabled>
				<ListItemIcon>
					<AddBox fontSize="small" />
				</ListItemIcon>
				<Typography noWrap>Create New Organisation</Typography>
			</MenuItem>
		);
	};

	return (
		<>
			<IconButton onClick={toggleUserMenu}>
				<RsUserAvatar user={user} ref={anchorRef} />
			</IconButton>
			<Popper
				id="userMenu"
				anchorEl={anchorRef.current}
				open={userMenuOpen}
				placement="bottom-end"
				sx={{
					'zIndex': 1,
					'color': theme.palette.primary.surfaceText,
					'.MuiListItemIcon-root': {
						color: 'inherit',
					},
				}}
			>
				<ClickAwayListener onClickAway={handleUserMenuClose}>
					<MenuList
						dense
						sx={{
							bgcolor: theme.palette.primary.surface,
							boxShadow: theme.shadows[6],
							borderRadius: theme.spacing(6),
						}}
					>
						<LoggedInMenuTopper />
						<Divider />
						{currentOrg && <LoggedInWithOrgsMenu />}
						<CreateNewOrgMenu />
						<Divider />
						<MenuItem onClick={handleLoginSettings} disabled>
							<ListItemIcon>
								<Lock fontSize="small" />
							</ListItemIcon>
							<Typography noWrap>User Login Settings</Typography>
						</MenuItem>
						<Divider />
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							<Typography noWrap>Log out</Typography>
						</MenuItem>
					</MenuList>
				</ClickAwayListener>
			</Popper>
			<SwitchDialog
				user={user}
				switchOpen={switchOpen}
				onClose={handleSwitchClose}
				handleCreateNewOrg={handleCreateNewOrg}
			/>
		</>
	);
};

export const GlobalHeader = ({ drawerButton, handleDrawerToggle, sx }) => {
	const theme = useTheme();
	const { user } = useCurrentUser();
	return (
		<Box id="GlobalHeader" sx={{ mb: 4 }}>
			<AppBar
				color="transparent"
				position="static"
				sx={{
					boxShadow: 'none',
					...sx,
				}}
			>
				<Toolbar
					sx={{ justifyContent: 'space-between', minHeight: '4rem' }}
				>
					{drawerButton && (
						<IconButton
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ m: 0, color: 'common.white' }}
						>
							<Menu />
						</IconButton>
					)}
					<Link
						component={RouterLink}
						to="/"
						sx={{
							display: 'block',
							lineHeight: 1,
						}}
					>
						<RsLogoType
							color={theme.palette.primary.white}
							fill={false}
							style={{ height: '2.8rem', marginTop: '0.2rem' }}
						/>
					</Link>
					{user ? (
						<LoggedInMenu user={user} theme={theme} />
					) : (
						<LoggedOutMenu theme={theme} />
					)}
					{/* <Button onClick={handleLogout}>Log&nbsp;out</Button> */}
				</Toolbar>
			</AppBar>
		</Box>
	);
};
