import { useState, useEffect } from 'react';
import { RsLogoMonoLoading } from '@common/assets';
import { useTheme } from '@mui/material/styles';

const Logo = ({ theme, count }) => {
	return (
		<>
			<RsLogoMonoLoading
				logoColor={theme.palette.secondary}
				style={{ height: '16rem' }}
				key={count}
			/>
		</>
	);
};

export const LogoAnimated = () => {
	const theme = useTheme();
	const [count, setCount] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => {
			setCount((count) => count + 1);
		}, 10000);
		return () => clearTimeout(timer);
	}, [count]);

	return <Logo theme={theme} count={count} />;
};
