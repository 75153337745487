/**
 * orgCreate_01.js
 * first step of the org create wizard
 * @name orgCreate_02.js
 */

import Grid from '@mui/material/Unstable_Grid2';
import { MenuItem, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Input } from '@common/components/forms';
import { Field } from 'formik';
import { OrgProfileBadge } from '@common/components';
import { orgConfigOptions } from '@app/appConfig';

/**
 * Component - the form component for this step
 * @returns {React.Component}
 */
export const CreateOrg_03 = ({ ...props }) => {
	const theme = useTheme();
	const isMdOrAbove = useMediaQuery(theme.breakpoints.up('md'));
	const { errors, touched, values } = props;
	return (
		<Grid container xxs={12} spacing={4}>
			<Grid xxs={12} md={8} spacing={4}>
				<Field
					component={Input}
					name="department"
					select
					label="Department/Team"
					fullWidth
					intro="What department or team are you in?"
					caption="Choose the best match from the list above"
					error={touched?.department && !!errors?.department}
					helperText={touched?.department && errors?.department}
					sx={{ my: 4 }}
				>
					{orgConfigOptions.departments.map((option, index) => (
						<MenuItem key={index} value={option}>
							{option}
						</MenuItem>
					))}
				</Field>
				<Field
					component={Input}
					name="jobTitle"
					type="text"
					label="Your job title"
					fullWidth
					intro={`What is your title within your team?`}
					caption="e.g. People Ops, Team Leader, CTO"
					placeholder="Job Title"
					error={touched?.jobTitle && !!errors?.jobTitle}
					helperText={touched?.jobTitle && errors?.jobTitle}
					sx={{ my: 4 }}
				/>
				<Field
					component={Input}
					name="displayName"
					type="text"
					label="Display name"
					fullWidth
					intro={`What name would you like on your profile?`}
					caption="e.g. John Smith, John S, John"
					placeholder="Display Name"
					error={touched?.displayName && !!errors?.displayName}
					helperText={touched?.displayName && errors?.displayName}
					sx={{ my: 4 }}
				/>
			</Grid>
			{isMdOrAbove && (
				<>
					<Grid md="auto" sx={{ textAlign: 'center' }}>
						<Divider
							orientation="vertical"
							sx={{ display: 'inline-block' }}
						/>
					</Grid>
					<Grid
						md
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<OrgProfileBadge org={values} />
					</Grid>
				</>
			)}
		</Grid>
	);
};
