import { Typography, Button, Divider } from '@mui/material';
import { getUrlByEnvironment } from '@common/utils';
import { useRsNavigate, useCurrentUser } from '@common/hooks';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

export const Intro = ({ hideIntro }) => {
	const { navigateTo } = useRsNavigate();
	const { user } = useCurrentUser();
	const theme = useTheme();
	return (
		<Grid container spacing={4} sx={{ textAlign: 'center' }}>
			<Grid xxs md={2}></Grid>
			<Grid
				container
				xxs={12}
				md={8}
				sx={{
					color: theme.palette.primary.surfaceText,
				}}
			>
				<Grid xxs={12} sx={{}}>
					<Typography
						variant="h1"
						component="h1"
						sx={{ textAlign: 'center', pb: 4 }}
					>
						G'Day 👋 {user.displayName}
					</Typography>
					<Typography
						variant="h3"
						sx={{ textAlign: 'center', pb: 4 }}
					>
						Let's do this!
					</Typography>
				</Grid>
				<Grid xxs={12}>
					<Typography variant="h3" sx={{ pb: 4 }}>
						Create an{' '}
						<em>
							<b>Organisation</b>
						</em>{' '}
						to bring your people together.
					</Typography>
				</Grid>
				<Grid xxs={12}>
					<Button
						variant="contained"
						color="primary"
						sx={{ fontWeight: 'bold' }}
						onClick={hideIntro}
					>
						Create a new Organisation
					</Button>
				</Grid>
				<Grid xxs>
					<Divider sx={{ my: 4 }} />
				</Grid>
				<Grid xxs={12}>
					<Typography variant="body2" sx={{ my: 2 }}>
						Has someone already created your Organisation?
					</Typography>
					<Button
						variant="button"
						onClick={() => {
							const appUrl = getUrlByEnvironment('app');
							navigateTo(appUrl + '/request-invite');
						}}
					>
						Send a request to join
					</Button>
				</Grid>
			</Grid>
			<Grid xxs md={2}></Grid>
		</Grid>
	);
};
