import { decodeJwt as decodeJwtToken } from 'jose';

/**
 * Takes an object and an array of keys and returns a new object without any of
 * the keys/values that were in the provided array.
 * @param {Object} obj - the parent object to filter values from
 * @param {Array} keys - an array of keys to use to filter values
 * @return {Object} - an object without the filtered keys/values
 */
export const without = (obj, keys = []) => {
	return Object.fromEntries(
		Object.entries(obj).filter(([key]) => !keys.includes(key)),
	);
};

/**
 * Takes an object and an array of keys and returns a new object with only
 * the keys/values that were in the array.
 * @param {Object} obj - the parent object to pluck values from
 * @param {Array} keys - an array of keys to use to pluck values from the object
 * @return {Object} - an object with only the plucked keys/values
 */
export const pluck = (obj, keys = []) => {
	return Object.fromEntries(
		Object.entries(obj).filter(([key]) => keys.includes(key)),
	);
};

/**
 * returns a parameter from a URL string
 * defaults to using the current window location if no URL is provided
 * @param {string} name - the parameter variable name
 * @param {string} [url = window.location.href] - the URL string to search
 * @return {string} value - the value of the parameter
 */
export const getParamByNameFromUrl = (name, url = window.location.href) => {
	const params = new URL(url).searchParams;
	const param = params.get(name);
	log('getParamByNameFromUrl:', name, param);
	return param;
};

/**
 * fetches a cookie from a browser environment by name and returns its value
 * returns an empty string if the cookie is not found
 * @param {string} name - the name of the cookie to fetch
 * @return {string} value - the value of the cookie or ''
 */
export const getCookieByName = (name) => {
	const cookie = document.cookie
		.split(';')
		.map((row) => row.trim())
		.find((row) => row.startsWith(`${name}=`));
	return cookie ? cookie.substring(`${name}=`.length) : '';
};

/**
 * An async function that will pause for the specified number of milliseconds.
 * This can be used within any async function to pause execution:
 * `await wait(1000);`
 * @param {Number} ms
 */
export const wait = async (ms) => {
	await new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * decodeJwt - decodes a JWT token without verifying and returns
 * the unverified payload
 * @param {String} token - the JWT token to decode
 * @return {Object} payload - the unverified decoded JWT payload
 */
export const decodeJwt = (token) => {
	const decodedToken = decodeJwtToken(token);
	// log('utils decodedJwt:', decodedToken);
	return decodedToken;
};

/** textTools provides a small number of helper functions for manipulating
 * text strings (like capitalize, camelCase, slugify, etc)
 */
export const textTools = {
	capitalize: (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	},
	encode: (str) => btoa(str), // base64 encode string
	decode: (b64) => atob(b64), // base64 decode string
};

/** createRandomString
 * create a string of length n with random alphanumeric characters
 * @param {number} length - the length of the string to create
 * @return {string} - the random string
 */
export const createRandomString = (length) => {
	const chars = '123456789bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ';
	let result = '';
	for (let i = length; i > 0; --i)
		result += chars[Math.floor(Math.random() * chars.length)];
	return result;
};

/** createCookie
 * create a new cookie with the specified name and value
 * @param {string} name - the name of the cookie
 * @param {string} [value = ''] - the value of the cookie
 * @param {string} [domain = null] - the domain of the cookie - defaults to current subdomain
 * @param {number} [days = null] - the number of days until the cookie expires
 * @param {boolean} [secure = true] - if true, the cookie will be set as a secure
 * HTML only cookie
 */
export const createCookie = (
	name,
	value = '',
	domain = null,
	days = null,
	secure = true,
) => {
	if (!name) return; // short circuit if no name is provided
	if (!domain) domain = window.location.hostname;
	if (!days || typeof days !== 'number') {
		days = '';
	} else {
		days = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
	}
	log('days', days);

	const domainStr = `; domain=${domain}; path=/`;
	const expiresStr = `; expires=${days}`;
	const secureStr = secure ? '; secure; samesite=strict' : '';
	const cookieStr = `${name}=${value}${domainStr}${expiresStr}${secureStr}`;

	log('cookieStr', cookieStr);

	// set the cookie with all the computed values
	document.cookie = cookieStr;
};

export const readCookie = (name) => {
	return getCookieByName(name);
};

/** deleteCookie - sets the cookie to a blank value with an expiry date of
 * one day in the past. This should effectively remove the cookie as it has
 * already expired.
 * @param {string} name - the name of the cookie to delete
 * @param {string} [domain = null] - the domain of the cookie - defaults to current subdomain
 */
export const deleteCookie = (name, domain = null) => {
	createCookie(name, '', domain, -1);
};
