/**
 * orgCreate_01.js
 * first step of the org create wizard
 * @name orgCreate_02.js
 */
import { useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
	Box,
	Slider,
	Typography,
	MenuItem,
	Divider,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Input, Combo } from '@common/components/forms';
import { Field } from 'formik';
import { OrgProfileBadge } from '@common/components';
import { orgConfigOptions } from '@app/appConfig';

const SizeSlider = ({ field, form, ...props }) => {
	const sizes = orgConfigOptions.sizes;
	const sliderText = (value) => {
		return sizes[value].text;
	};
	return (
		<Box>
			<Typography variant="h4" sx={{ mb: 10 }}>
				Number of employees
			</Typography>
			<Box sx={{ ml: '5%', mr: '10%' }}>
				<Slider
					{...field}
					aria-label="Always visible"
					valueLabelDisplay="on"
					valueLabelFormat={sliderText}
					getAriaValueText={sliderText}
					marks={sizes}
					min={0}
					max={4}
					step={null}
					defaultValue={1}
					{...props}
				/>
			</Box>
		</Box>
	);
};

const IndustrySelect = ({ field, form, ...props }) => {
	return (
		<Box sx={{ my: 4 }}>
			{}
			<Input {...field} {...props}>
				{orgConfigOptions.industries.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</Input>
		</Box>
	);
};

/**
 * Component - the form component for this step
 * @returns {React.Component}
 */
export const CreateOrg_02 = ({ ...props }) => {
	const theme = useTheme();
	const isMdOrAbove = useMediaQuery(theme.breakpoints.up('md'));
	const { errors, touched } = props;
	const timezoneRef = useRef();
	return (
		<Grid container xxs={12} spacing={4}>
			<Grid xxs={12} md={8} spacing={4}>
				<Field name="size" component={SizeSlider} />
				<Field
					name="industry"
					component={IndustrySelect}
					select
					fullWidth
					label="Organization industry"
					intro="Which industry are you in"
					caption="Choose the best match from the list above"
					placeholder="e.g. Education, Healthcare, etc."
					onChange={props.handleChange}
					error={touched?.industry && !!errors?.industry}
					helperText={touched?.industry && errors?.industry}
				/>
				<Field
					name="timezone"
					component={Combo}
					selectOnFocus={true}
					clearOnBlur={true}
					options={orgConfigOptions.timezones}
					inputRef={timezoneRef}
					fullWidth
					label="Organization Timezone"
					intro="What is your organization's primary timezone?"
					caption="Type to search for your timezone"
					placeholder="e.g. America/Los_Angeles"
					onChange={props.handleChange}
					error={touched?.timezone && !!errors?.timezone}
					helperText={touched?.timezone && errors?.timezone}
				/>
			</Grid>
			{isMdOrAbove && (
				<>
					<Grid md="auto" sx={{ textAlign: 'center' }}>
						<Divider
							orientation="vertical"
							sx={{ display: 'inline-block' }}
						/>
					</Grid>
					<Grid
						md
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<OrgProfileBadge org={props.values} />
					</Grid>
				</>
			)}
		</Grid>
	);
};
