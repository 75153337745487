import { useContext } from 'react';
import { ChatAppContext } from '@common/contexts';

/**
 * returns the configuration setup for the messaging, a method
 * for changing the platform that the configuration relates to, and the current
 * instance that is active for the current user/org.
 *
 * Possible platform options are:
 * - 'slack' (default)
 * - 'msTeams'
 *
 * @return {object} { config, setPlatform, instance }
 */
export const useChatApp = () => {
	const context = useContext(ChatAppContext);
	if (context === undefined) {
		throw new Error(
			'useMessagingApp must be called within MessagingAppContextProvider',
		);
	}
	return context;
};
