/** handle display of the Wordle Connect game */
import { useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { SectionHeader, SectionContent } from '@app/layouts';
import { RsWizard, RsButton } from '@common/components';
import { useAsync, useRsNavigate } from '@common/hooks';
import { ContentHeader } from '@app/layouts';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
	useConnect,
	useSelectChannel,
	useSelectStart,
	// useSelectEnd,
} from '@app/hooks/connect';

export const Gratituesday = () => {
	const { navigateTo } = useRsNavigate();
	const handleCreate = () => {
		navigateTo('/connect/gratituesday/create');
	};
	const handleBack = () => {
		navigateTo('/connect');
	};
	const Menu = () => (
		<>
			<RsButton variant="text" onClick={handleBack}>
				Back
			</RsButton>
		</>
	);

	const HeaderContent = () => (
		<>
			<Typography variant="h5" sx={{ mb: 4 }}>
				Share moments of joy and thankfulness with our weekly team
				prompt that encourages everyone to reflect on what they are
				grateful for.
			</Typography>
			<Typography variant="body1">
				Each Tuesday we'll post a prompt that can spark amazing
				conversations and moments of sharing.
			</Typography>
		</>
	);

	return (
		<>
			<ContentHeader
				title="Gratituesday"
				menu={<Menu />}
				content={<HeaderContent />}
			/>
			<Typography variant="h3">What are you grateful for?</Typography>
			<Grid container spacing={8} sx={{ my: 8 }}>
				<Grid xs={12} sm={8}>
					<Typography variant="body1" sx={{ mb: 4 }}>
						The power of Gratitude on mental health and wellbeing is
						well studied. Introducing a short moment of reflection
						each week can have a huge impact on your entire team.
					</Typography>
					<Typography>
						Gratitude helps people build strong relationships,
						improve their health, feel more positive emotions,
						relish good experiences, and better deal with adversity.
					</Typography>
				</Grid>
				<Grid xs={12} sm={4} sx={{ textAlign: 'center' }}>
					<img
						src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/gratitude_01.jpg"
						width="100%"
						height="auto"
						style={{
							border: '4px solid #FFF',
							borderRadius: '1rem',
							boxShadow: '0 0 0.5rem rgba(87,3,200,0.5)',
							transform: 'rotate(-2deg) translate(0, 0)',
						}}
						alt="Wordle title"
					/>
				</Grid>
			</Grid>
			<Container sx={{ my: 4, textAlign: 'center' }}>
				<RsButton
					variant="contained"
					onClick={handleCreate}
					sx={{ mr: 2 }}
				>
					Get started with Gratituesday
				</RsButton>
			</Container>
			<Container maxWidth="xs" sx={{ my: 10 }}>
				<img
					src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/gratituesday-sample-post.png"
					width="100%"
					height="auto"
					style={{
						border: '4px solid #FFF',
						borderRadius: '1rem',
						boxShadow: '0 0 0.5rem rgba(87,3,200,0.5)',
					}}
					alt="Gratituesday sample"
				/>
			</Container>
			<Container maxWidth="xs" sx={{ my: 10 }}>
				<Typography variant="h4" sx={{ mb: 4 }}>
					Our Recommended Settings
				</Typography>
				<hr />
				<ul>
					<li>Posts every Tuesday (default setting)</li>
					<li>Set start time in the morning your local time</li>
					<li>Add to an existing social channel in your workspace</li>
				</ul>
			</Container>
			<Container maxWidth="xs" sx={{ my: 10 }}>
				<Typography variant="h4" sx={{ mb: 4 }}>
					Example posts
				</Typography>
				<hr />
				<Grid container spacing={4}>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/1.png"
							width="100%"
							height="auto"
							alt="Sample post 1"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/2.png"
							width="100%"
							height="auto"
							alt="Sample post 2"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/3.png"
							width="100%"
							height="auto"
							alt="Sample post 3"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/4.png"
							width="100%"
							height="auto"
							alt="Sample post 4"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/5.png"
							width="100%"
							height="auto"
							alt="Sample post 5"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/6.png"
							width="100%"
							height="auto"
							alt="Sample post 6"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/7.png"
							width="100%"
							height="auto"
							alt="Sample post 7"
						/>
					</Grid>
					<Grid xxs={12} md={6}>
						<img
							src="https://cdn.remotesocial.io/rs2/connect/Gratituesday/prompts/8.png"
							width="100%"
							height="auto"
							alt="Sample post 8"
						/>
					</Grid>
				</Grid>
				<hr />
				<Typography variant="body1" sx={{ mt: 4 }}>
					There are 26 weeks of prompts available.
				</Typography>
				<Typography variant="body1" sx={{ mt: 4 }}>
					The pack will restart and loop continuously after that.
				</Typography>
			</Container>
			<Container sx={{ my: 4, textAlign: 'center' }}>
				<RsButton
					variant="colored"
					onClick={handleCreate}
					sx={{ mr: 2 }}
				>
					Get started with Gratituesday
				</RsButton>
			</Container>
		</>
	);
};

export const GratituesdayCreate = () => {
	const formikBagRef = useRef(null);
	const { navigateTo } = useRsNavigate();
	const { createActivity: createActivityFunction } = useConnect();

	const [createActivity, triggerCreateActivity] = useAsync(
		createActivityFunction,
	);

	const onReset = (values, formikBag) => {
		log('GratituesdayCreate - onReset', values);
		navigateTo('/connect/gratituesday');
	};

	const onSubmit = async (values, formikBag) => {
		log('GratituesdayCreate - onSubmit', values);
		formikBagRef.current = formikBag;
		await triggerCreateActivity('gratituesday', values);
	};

	const handleServerError = useCallback((error) => {
		log('GratituesdayCreate - handleServerError', error);
		const formikBag = formikBagRef.current;
		if (formikBag) {
			formikBag.setErrors(error.details || { server: error.message });
			formikBag.setStatus({
				state: 'error',
				message: error.message,
			});
			formikBag.setSubmitting(false);
		}
	}, []);

	const handleServerResponse = useCallback(
		(response) => {
			log('GratituesdayCreate - handleServerResponse', response);
			const formikBag = formikBagRef.current;
			if (formikBag) {
				formikBag.setStatus({
					state: 'success',
					message: `Activity created: ${response}`,
				});
				formikBag.setSubmitting(false);
			}
			navigateTo('/connect');
		},
		[navigateTo],
	);

	useEffect(() => {
		const { loading, error, response } = createActivity;
		if (loading) return;
		if (error) return handleServerError(error);
		if (response) return handleServerResponse(response);
	}, [createActivity, handleServerError, handleServerResponse]);

	const step1 = useSelectChannel({
		preferCreate: true,
		preamble: '',
	});
	const step2 = useSelectStart({
		preamble: '',
	});

	const Menu = () => {
		return (
			<>
				<RsButton variant="text" onClick={onReset}>
					Back
				</RsButton>
			</>
		);
	};

	return (
		<>
			<ContentHeader title="Gratituesday" menu={<Menu />} />
			<Container>
				<RsWizard
					steps={[step1, step2]}
					formikConfig={{
						validateOnBlur: true,
						validateOnChange: true,
						validateOnMount: false,
						onSubmit: onSubmit,
						onReset: onReset,
					}}
				/>
			</Container>
		</>
	);
};

export const GratituesdayActivity = () => {
	const { activityId } = useParams();
	const handleEdit = () => {
		log('edit');
	};
	const EditButton = () => {
		return <RsButton>Edit</RsButton>;
	};
	return (
		<>
			<SectionHeader
				title="Gratituesday"
				menu={<EditButton onClick={handleEdit} />}
			/>
			<SectionContent>
				<h1>Gratituesday activity: {activityId}</h1>
			</SectionContent>
		</>
	);
};
