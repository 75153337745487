import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Apartment, AccountCircle } from '@mui/icons-material';
import { Box, Avatar, Typography } from '@mui/material';

export const RsUserAvatar = forwardRef(
	({ user, size = '2.5rem', sx, ...rest }, ref) => {
		const theme = useTheme();

		if (!user.photoURL) {
			rest.children = <AccountCircle />;
		}

		return (
			<Avatar
				ref={ref}
				alt={user.displayName || 'User Avatar'}
				src={user.photoURL}
				sx={{
					border: `1px solid ${theme.palette.primary.contrastText}`,
					height: size,
					width: size,
					...sx,
				}}
				{...rest}
			/>
		);
	},
);

/** RsOrgAvatar
 * returns an avatar for an organisation using the org's photoURL value
 * The avatar will have a square aspect ratio (1:1) and rounded corners
 * There will be a primary.main colored 1px border
 * The avatar can match a few different variants
 * - vertical (Org name on the bottom of the avatar)
 * - horizontal (Org name on the right of the avatar)
 * - badge (Custom style used in new Org Creation)
 * if no org photoURL is provided then a default icon (Apartment) is used
 * @param {object} org - the organisation object
 * @param {string} [variant='vertical'] - the variant of the avatar
 * @param {string} [size='2.5rem'] - the size of the avatar
 * @param {boolean} [title=false] - whether to show the org name or not
 * @param {object} [sx] - the sx object
 */

export const RsOrgAvatar = forwardRef(
	(
		{ org, variant = 'vertical', size = '2.5rem', title, sx, ...rest },
		ref,
	) => {
		if (!org.photoURL || org.photoURL === '') {
			rest.children = <Apartment sx={{ width: '75%', height: '75%' }} />;
		}

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: variant === 'horizontal' ? 'row' : 'column',
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: size,
				}}
			>
				<Avatar
					className="orgAvatar"
					ref={ref}
					alt={org.name || 'Organisation Avatar'}
					src={org.photoURL}
					variant="rounded"
					sx={{
						color: 'inherit',
						backgroundColor: 'common.white',
						borderWidth: 1,
						borderStyle: 'solid',
						borderColor: 'primary.dark',
						borderRadius:
							variant === 'badge'
								? '0.5rem 0.5rem 0 0'
								: '0.5rem',
						padding: '0.5rem',
						width: size,
						height: size,
						...sx,
					}}
					{...rest}
				/>
				{title && (
					<Typography
						variant="subtitle2"
						textAlign="center"
						sx={{
							backgroundColor:
								variant === 'badge' ? 'primary.dark' : 'none',

							borderRadius:
								variant === 'badge' ? '0 0 0.5rem 0.5rem' : 0,
							width: variant === 'badge' ? '100%' : 'auto',
							overflow: 'hidden',
							fontSize: '0.9rem',
							fontWeight: 'inherit',
							textTransform: 'inherit',
							lineHeight: '1',
							height: '1.4rem',
							margin: '0 auto 0.5rem',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							padding: '0.25rem 0.4rem',
							color:
								variant === 'badge'
									? 'primary.contrastText'
									: 'primary.surfaceText',
						}}
					>
						{org.name || 'New Org'}
					</Typography>
				)}
			</Box>
		);
	},
);
