import { forwardRef } from 'react';
import { TextField as MuiTextField, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const getTextFieldStyles = (color, errorColor, sx) => ({
	'mt': 3,
	'.MuiOutlinedInput-root': {
		'color': `${color.containerText}cc`, // make semi-transparent
		'background': `${color.surface}77`, // make semi-transparent
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: `${color.containerText}6f`, // make semi-transparent
			borderWidth: '1px',
		},
		'input': {
			borderRadius: 'inherit',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			pl: 3,
		},
		'.MuiInputAdornment-root, .MuiIconButton-root': {
			color: 'inherit',
		},
		'&:hover': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}99`, // make semi-transparent
				borderWidth: '1.5px',
			},
		},
		'&.Mui-focused': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}cc`, // make semi-transparent
				borderWidth: `2.5px`,
			},
		},
		'&.Mui-disabled': {
			'color': `${color.containerText}99`, // make semi-transparent
			'background': `${color.container}aa`, // make semi-transparent
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}55`, // make semi-transparent
			},
			'.MuiOutlinedInput-input, input:disabled': {
				TextFillColor: 'inherit',
				WebkitTextFillColor: 'inherit',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: `${color.containerText}55`, // make semi-transparent
				borderWidth: '1px',
			},
		},
		'&.Mui-error': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `${errorColor.main}cc`, // make semi-transparent
				borderWidth: '2px',
			},
		},
	},
	'.MuiFormHelperText-root': {
		'fontWeight': 'bold',
		'color': `${color.contrastText}`,
		'p': 2,
		'mx': 1,
		'my': 0,
		'fontSize': '0.8rem',
		'borderRadius': '0 0 0.25rem 0.25rem',
		'background': `${color.main}cc`, // make semi-transparent
		'&.Mui-error': {
			background: `${errorColor.main}cc`, // make semi-transparent
			color: `${errorColor.contrastText}`,
		},
	},
	...sx,
});

/**
 * a reusable form text field component
 * @param {number} [tabIndex] - the tab index for the field
 * @param {Boolean} [autoFocus] - whether the field should be focused on render
 * @param {string} [color="primary"] - the theme color to use for the field
 * @param {string} [intro] - an intro text to display above the field
 * @param {string} [caption] - a caption text to display below the field
 * @param {props} props - any other props to pass to the TextField
 * @return {React.Component} - the TextField component
 */
const TextField = ({
	color = 'primary',
	intro,
	caption,
	tabIndex, // we're going to pass this to the inputProps so we don't want it to be passed to the TextField with props
	...props
}) => {
	const theme = useTheme();
	const col = theme.palette[color];
	const error = color.endsWith('Dark') // if the provided palette ends with 'Dark'
		? theme.palette.errorDark // use the dark error palette
		: theme.palette.error; // otherwise use the regular error palette
	const inputProps = {
		tabIndex,
		...props.inputProps,
	};

	if (props.select) {
		props.sx = {
			...props.sx,
			my: 4,
		};
	}
	return (
		<Box sx={props.sx}>
			{intro && (
				<Typography variant="h4" color={col}>
					{intro}
				</Typography>
			)}
			<MuiTextField
				{...props}
				inputProps={inputProps}
				sx={getTextFieldStyles(col, error)}
			/>
			{caption && <Typography variant="caption">{caption}</Typography>}
		</Box>
	);
};

/**
 * a reusable form text field component
 * that uses Material-ui's TextField component
 * Specifically uses Formik ({field, form, ...props}) props
 * filters out the "form" values from the field
 */
export const Input = forwardRef(({ field, form, ...props }, ref) => {
	return <TextField inputRef={ref} {...field} {...props} />;
});
