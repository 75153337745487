import { useMemo } from 'react';

import { useChatApp } from '@common/hooks';
import { textTools } from '@common/utils/tools';
import { Combo } from '@common/components/forms';

import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { Tag } from '@mui/icons-material';

import { Field } from 'formik';
import Joi from 'joi';

/** SelectChannel
 *
 * Allows the selection of a channel by the user to utilize in their Connect
 * setup process.
 *
 * Is designed to be used with the RsWizard component. Returns
 * a step object including the validation rules and initialValues to be used
 * in the form as well as a UI component to render the form step to the user.
 *
 * @return {Object} a step object for use in the RsWizard component
 * @return {String} step.label - the label for the step
 * @return {Object} step.initialValues - the initial values for the step
 * @return {Object} step.validate - the validation schema for the step
 * @return {React.Component} step.Component - the UI component for the step
 */
export const useSelectChannel = (options) => {
	const { preferCreate, preamble } = options;
	/** access the current installed messagingApp team data */
	const { team } = useChatApp();

	/** return the Step payload object - including the Component */
	return useMemo(() => {
		/** get the team channels, and the platform name for use in the label */
		const channelOptions =
			team?.channels?.map((c) => c.chatChannelName) || [];
		const chatId = textTools.capitalize(team?.chatId);
		const label = preferCreate
			? `Create ${chatId} channel`
			: `Select ${chatId} channel`;
		const caption = preferCreate
			? `Enter a new channel name, or select an existing one`
			: `Select an existing channel, or enter a new channel name`;

		const Component = ({ touched, errors, ...props }) => {
			return (
				<>
					{preamble && (
						<Typography sx={{ mb: 6 }}>{preamble}</Typography>
					)}
					{preferCreate ? (
						<>
							<Typography variant="body1" sx={{ my: 4 }}>
								We recommend you create a brand new channel for
								this activity.
							</Typography>
							<Typography variant="body1">
								Enter a new channel name below, or if you choose
								to use an existing channel, you can select it
								from the list below.
							</Typography>
						</>
					) : (
						<Typography variant="body1" sx={{ my: 4 }}>
							Choose an existing channel below, or enter the name
							of a new channel that you would like to create for
							this activity.
						</Typography>
					)}
					<Grid container xxs={12} spacing={4}>
						<Grid xxs={12}>
							<Field
								name="channelName"
								component={Combo}
								options={channelOptions}
								fullWidth
								label={label}
								caption={caption}
								placeholder="e.g. #wordle"
								InputProps={{ startAdornment: <Tag /> }}
								error={
									touched?.channelName &&
									!!errors?.channelName
								}
								helperText={
									touched?.channelName && errors?.channelName
								}
							/>
						</Grid>
					</Grid>
				</>
			);
		};

		return {
			label: `Select ${chatId} channel`,
			initialValues: {
				channelName: '',
			},
			validate: {
				channelName: Joi.string()
					.min(3)
					.max(80)
					.pattern(/^[a-z0-9_-]+$/)
					.required()
					.messages({
						'string.empty': `Channel name is required`,
						'string.base': 'Channel name must be a text',
						'string.min': `Channel name must be at least {#limit} characters long`,
						'string.max': `Channel name cannot be more than {#limit} characters long`,
						'string.pattern.base': `Channel names can only contain lowercase letters, numbers, underscores, and dashes`,
					}),
			},
			Component,
		};
	}, [team, preferCreate, preamble]);
};
