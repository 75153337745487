import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useCurrentUser, useRsNavigate } from '@common/hooks';
import { AuthRoute } from '@common/routes';
import { getUrlByEnvironment, createEncodedContinueUrl } from '@common/utils';

const OrgRouteInternal = ({ children }) => {
	const { loading, user } = useCurrentUser();
	const { navigateTo } = useRsNavigate();

	useEffect(() => {
		if (loading) return;
		if (!user || !user.currentOrg) {
			/**
			 * Create a new continueUrl from this url and forward the user
			 * along to the createOrg UI.
			 */
			const appAppDomain = getUrlByEnvironment('app');
			const encodedContinueUrl = createEncodedContinueUrl();
			const dest = `${appAppDomain}/create?continueUrl=${encodedContinueUrl}&showIntro=true`;
			log('🔀 OrgRoute: user.currentOrg not present - redirect...', dest);
			navigateTo(dest, { replace: true });
			return;
		}
	}, [loading, user, navigateTo]);

	if (loading || !user || !user.currentOrg) return <></>;

	// log('🔀 OrgRoute: ✅ user.currentOrg is loaded, render component');
	return children ? children : <Outlet />;
};

/** OrgRoute
 * Verify the user is:
 * 1. logged-in
 * 2. has a user.currentOrg defined.
 *
 * Will be true for any role user (verified, unverified, anonymous...)
 * Usage (inside <Routes> component) with an Outlet:
 * <Route element={\<OrgRoute /\>}>
 * 		<Route path="/connect/*" element={\<Connect /\>} />
 * </Route>
 *
 * or
 *
 * Usage (inside <Routes> component) without an Outlet (children):
 * <Route path="/connect/*" element={\<OrgRoute\>\<Connect /\>\</OrgRoute\>}>
 *
 * OrgRoute always includes the AuthRoute check, so you don't need to do both!
 **/
export const OrgRoute = ({ children }) => {
	return (
		<AuthRoute>
			<OrgRouteInternal />
		</AuthRoute>
	);
};
