import { useMemo, useState, useEffect } from 'react';
import { useCurrentUser } from '@common/hooks';
import { Date, Time, Combo } from '@common/components/forms';

import { orgConfigOptions } from '@app/appConfig';

import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

import { Field } from 'formik';
import { DateTime as DT } from 'luxon';
import Joi from 'joi';

/**
 * During Connect Activity creation the users is asked to choose a schedule that
 * they want the activity to run. This may include hourly, daily schedules,
 * weekly, monthly, or even yearly.
 *
 * This "component" actually returns a "step" object that can be used by the
 * RsWizard component to create a multi-step form.
 *
 * This step requires some config params to be passed in to determine the type
 * of schedule that is being created. The config params are:
 * - repeating / one-off*
 * - repeating frequency (hourly, daily, weekly, monthly, yearly)*
 * - repeating constraints (days to omit from options, etc...)?
 */

/**
 * Round supplied datetime up to the nearest round quarter hour
 * (15, 30, 45, 00)
 * @param {luxon.DateTime}  dt - luxon DateTime object
 * @return {luxon.DateTime} rounded up to the nearest quarter hour
 */
const roundUpToNextQuarterHour = (dt) => {
	const minutes = dt.minute;
	const remainder = minutes % 15;
	return dt.plus({ minutes: 15 - remainder }).startOf('minute');
};

/** getNextBusinessDay returns the very next day after today constrained to
 * only business (mon-fri) days. Returns a datetime object
 */
// const getNextBusinessDay = (timezone) => {
// 	const now = DT.now().setZone(timezone);
// 	let next = now.plus({ day: 1 });
// 	while (next.weekday > 5) {
// 		next = next.plus({ day: 1 });
// 	}
// 	return next;
// };

/**
 * Given a targetTime {hours, mins, seconds} return the next occurence of that
 * time constrained to only business days (mon - fri). If the time now is before
 * the target time, return today's date at the target time, otherwise return the
 * next business day at the target time.
 */
// const getNextTimeOfBusinessDay = (targetTime, timezone) => {
// 	const target = DT.now().setZone(timezone).set({
// 		hour: targetTime.hours,
// 		minute: targetTime.minutes,
// 		second: targetTime.seconds,
// 		millisecond: targetTime.milliseconds,
// 	});
// 	const now = DT.now().setZone(timezone);
// 	if (now < target) {
// 		return target;
// 	}
// 	return target.plus({ day: 1 });
// };

/** useSelectStart
 * A custom hook that returns a step configuration object.
 *
 * During Connect Activity creation the users is asked to choose the date and
 * time to start the activity
 *
 * @param {String} type - 'repeating' or 'once'
 * @param {Object} [config] - config options for the schedule
 * @param {String} config.label - the label for the step
 * @param {String} config.frequency - 'hourly', 'daily', 'weekly', 'monthly', 'yearly'
 * @param {Object} config.frequencyConfig - additional configuration for the frequency (days to omit, starting values);
 * @param {Object} config.initialValues - any initial values for the step
 * @return {Object} - a step object for use in the RsWizard component
 * @return {String} - step.label - the label for the step
 * @return {Object} - step.initialValues - the initial values for the step
 * @return {Object} - step.validate - the validation schema for the step
 * @return {React.Component} - step.Component - the component for the step
 */
export const useSelectStart = (options) => {
	const { user } = useCurrentUser();
	const [zoneName, setZoneName] = useState(
		user?.currentOrg?.details?.timezone || DT.now().zoneName,
	);

	useEffect(() => {
		setZoneName(user?.currentOrg?.details?.timezone || DT.now().zoneName);
	}, [user]);

	return useMemo(() => {
		const Component = ({
			setFieldTouched,
			setFieldValue,
			values,
			errors,
			touched,
			...rest
		}) => {
			values.startDate = values.dtStart;
			values.startTime = values.dtStart;

			let dateError;
			let timeError;
			if (errors?.dtStart?.startsWith('Start time')) {
				timeError = errors.dtStart;
			} else {
				dateError = errors.dtStart;
			}

			const handleDateChange = (value) => {
				setFieldTouched('dtStart', true, false);
				setFieldValue('dtStart', value);
			};

			const handleTimeChange = (value) => {
				setFieldTouched('dtStart', true, false);
				setFieldValue('dtStart', value);
			};

			return (
				<Grid xxs={12} spacing={4}>
					{options.preamble && (
						<Grid xxs={12}>
							<Typography variant="body1">
								{options.preamble}
							</Typography>
						</Grid>
					)}
					<Grid xxs={12}>
						<Field
							component={Combo}
							name="timezone"
							label="Select Time zone"
							intro="Activity time zone"
							caption="Select the time zone for the activity to run in"
							error={touched.timezone && !!errors.timezone}
							helperText={errors.timezone}
							options={orgConfigOptions.timezones}
							selectOnFocus={true}
							clearOnBlur={true}
						/>
					</Grid>
					<Grid xxs={12}>
						<Date
							name="startDate"
							value={values.dtStart}
							label={`Select Start Date in ${values.timezone}`}
							intro="Activity start date"
							caption="Within the next month"
							error={!!dateError}
							helperText={dateError}
							fullWidth={true}
							sx={{ my: 2 }}
							// show the date in the selected timezone
							timezone={values.timezone}
							onChange={handleDateChange}
						/>
					</Grid>
					<Grid xss={12}>
						<Time
							name="startTime"
							value={values.dtStart}
							label={`Select Start Time in ${values.timezone}`}
							intro="Activity start time"
							caption="At least 15 minutes from now"
							error={!!timeError}
							helperText={timeError}
							timeSteps={{ minutes: 15 }}
							ampm={true}
							fullWidth={true}
							sx={{ my: 2 }}
							// show the time in the selected timezone
							timezone={values.timezone}
							onChange={handleTimeChange}
						/>
					</Grid>
				</Grid>
			);
		};

		const dtStart = roundUpToNextQuarterHour(
			DT.now().plus({ minutes: 15 }),
		).toISO();

		return {
			label: 'Select Start Date & Time',
			initialValues: {
				dtStart,
				timezone: zoneName,
			},
			validate: {
				/** Date field always publishes a milliseconds timestamp */
				dtStart: Joi.date()
					.required()
					.max(DT.now().plus({ month: 1 })) // start within 1 month
					.greater(DT.now().plus({ minutes: 15 })) // start in 15 minutes
					.min(DT.now().startOf('day'))
					.messages({
						'date.base': 'Please enter a valid start date and time',
						'any.required': 'Please enter a start date and time',
						'date.min': 'Start date cannot be in the past',
						'date.greater':
							'Start time must be at least 15 minutes from now',
						'date.max': 'Start date must be within 1 month',
					}),
				timezone: Joi.string()
					.required()
					.valid(...orgConfigOptions.timezones)
					.messages({
						'string.base': 'Please select a timezone',
						'any.required': 'Please select a timezone',
						'string.empty': 'Please select a timezone',
						'any.valid': 'Please select a valid timezone',
					}),
				startDate: Joi.date(),
				startTime: Joi.date(),
			},
			Component,
		};
	}, [options, zoneName]);
};
