/**
 * orgCreate_01.js
 * first step of the org create wizard
 * @name orgCreate_01.js
 */
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Input } from '@common/components/forms';
import { OrgProfileBadge } from '@common/components';
import { Field } from 'formik';
import { orgConfigOptions } from '@app/appConfig';

/**
 * Component - the form component for this step
 * @param {Object} props - props passed to the component
 * @returns {React.Component}
 */
export const CreateOrg_01 = ({ ...props }) => {
	const theme = useTheme();
	const isMdOrAbove = useMediaQuery(theme.breakpoints.up('md'));

	const domainBlacklist = orgConfigOptions.domainBlacklist;

	const { errors, touched, initialValues, values } = props;

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const getSampleName = (domain) => {
		const name = domain.split('.')[0];
		return domainBlacklist.includes(name) ? 'Acme' : capitalize(name);
	};

	return (
		<Grid container xxs={12} spacing={4}>
			<Grid xxs={12} md={8} spacing={4}>
				<Field
					component={Input}
					name="name"
					type="text"
					label="Organization name"
					fullWidth
					intro="Let's start with your organization's name"
					caption="Min 3 characters"
					placeholder={getSampleName(initialValues.domain)}
					onChange={props.handleChange}
					required
					error={touched.name && !!errors.name}
					helperText={touched.name && errors.name}
					sx={{ my: 4 }}
				/>
				<Field
					component={Input}
					name="domain"
					type="text"
					label="Organization domain"
					fullWidth
					intro="and your organisation's website domain"
					caption={`without "https://" or "www."`}
					placeholder={initialValues.domain}
					onChange={props.handleChange}
					required
					error={touched.domain && !!errors.domain}
					helperText={touched.domain && errors.domain}
					sx={{ my: 4 }}
				/>
			</Grid>
			{isMdOrAbove && (
				<>
					<Grid md="auto" sx={{ textAlign: 'center' }}>
						<Divider
							orientation="vertical"
							sx={{ display: 'inline-block' }}
						/>
					</Grid>
					<Grid
						md
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<OrgProfileBadge org={values} />
					</Grid>
				</>
			)}
		</Grid>
	);
};
