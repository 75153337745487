import { useState, useRef } from 'react';
import { useConnect } from '@app/hooks/connect';
import {
	// AppBar,
	Avatar,
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	// Paper,
	Tab,
	Tabs,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
} from '@mui/material';
// import { Close } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
// import { CreateActivity } from './CreateActivity';
// import { Blender } from './activities';
import { useRsNavigate } from '@common/hooks';
import { DateTime } from 'luxon';
import { textTools } from '@common/utils';

export const PhysicalChallenge = ({ handleShowCreate }) => {
	const theme = useTheme();
	const videoRef = useRef();

	const handleMouseEnter = () => {
		videoRef.current.play();
	};
	const handleMouseLeave = () => {
		videoRef.current.pause();
	};

	return (
		<Card
			raised
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			sx={{
				background: theme.palette.rsPink.surface,
				color: theme.palette.rsPink.main,
			}}
		>
			<CardActionArea>
				<CardHeader
					avatar={
						<Avatar
							aria-label="connect bot"
							sx={{
								bgcolor: theme.palette.rsTeal.container,
								fontSize: '1.6rem',
							}}
						>
							🤖
						</Avatar>
					}
					title="Physical Challenge"
					titleTypographyProps={{ variant: 'h4' }}
				>
					<Typography variant="h3" color="rsPink">
						Physical Challenge
					</Typography>
				</CardHeader>
				<CardMedia>
					<video
						width="100%"
						playsInline
						loop
						muted={true}
						poster="https://cdn.remotesocial.io/physical/img/Poster.png"
						ref={videoRef}
					>
						<source
							src="https://cdn.remotesocial.io/physical/video/PhysicalChallenge.mp4"
							type="video/mp4"
						/>
					</video>
				</CardMedia>
			</CardActionArea>
			<CardContent>
				<Typography>
					Stay healthy, have fun, challenge your team
				</Typography>
			</CardContent>
			<CardActions>
				<Button
					variant="outlined"
					color="rsPink"
					onClick={() => handleShowCreate('physicalChallenge')}
				>
					Let's Go!
				</Button>
			</CardActions>
		</Card>
	);
};

const ActivitiesData = ({ type, activities }) => {
	log('activitiesData', activities);
	const { navigateTo } = useRsNavigate();
	activities = activities.map((activity) => {
		const dtStart = DateTime.fromISO(
			activity?.appData?.activityData?.dtStart,
		).toLocaleString(DateTime.DATETIME_MED);
		const dtEnd = DateTime.fromISO(activity?.schedule?.endDateTime).toISO();
		const timezone = activity?.appData?.activityData?.timezone;
		return {
			...activity,
			dtStart,
			dtEnd,
			timezone,
		};
	});

	const handleActivitySelect = (event) => {
		// log the id of the activity that was clicked
		log('handleActivitySelect', event.currentTarget.id);
		navigateTo(`/connect/activity/${event.currentTarget.id}`);
	};

	return (
		<Box sx={{ mb: 4 }}>
			<TableContainer component={Box}>
				<Table size="small">
					<TableHead
						sx={{
							'& th': {
								fontWeight: 'bold',
							},
						}}
					>
						<TableRow>
							<TableCell>Channel</TableCell>
							<TableCell>Activity</TableCell>
							<TableCell
								sx={{
									display: { sm: 'table-cell', xxs: 'none' },
								}}
							>
								Start Date
							</TableCell>
							<TableCell
								sx={{
									display: { lg: 'table-cell', xxs: 'none' },
								}}
							>
								Timezone
							</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{activities &&
							activities.map((activity) => (
								<TableRow
									hover
									key={activity.orgActivityId}
									sx={{
										'& td': {},
										'&:last-child td, &:last-child th': {
											border: 0,
										},
										'cursor': 'pointer',
									}}
									id={activity.orgActivityId}
									onClick={handleActivitySelect}
								>
									<TableCell>
										#{activity.appData.chatChannelName}
									</TableCell>
									<TableCell>
										{textTools.capitalize(
											activity?.appData.activityType,
										)}
									</TableCell>
									<TableCell
										sx={{
											display: {
												sm: 'table-cell',
												xxs: 'none',
											},
										}}
									>
										{activity?.dtStart}
									</TableCell>
									<TableCell
										sx={{
											display: {
												lg: 'table-cell',
												xxs: 'none',
											},
										}}
									>
										{
											activity?.appData?.activityData
												?.timezone
										}
									</TableCell>
									<TableCell>{activity?.state}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

const TabPanel = (props) => {
	const { children, currentTab, index, ...rest } = props;
	return (
		<div
			role="tabpanel"
			hidden={currentTab !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			// style={{ padding: '0.75rem' }}
			{...rest}
		>
			{currentTab === index && <>{children}</>}
		</div>
	);
};

const ComingSoon = () => {
	return (
		<Box
			sx={{
				backgroundColor: 'primary.dark',
				color: 'primary.contrastText',
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				textAlign: 'center',
				p: 1,
			}}
		>
			<Typography variant="body2">Coming soon</Typography>
		</Box>
	);
};

const ActivityCard = ({
	onClick = () => {},
	onMouseEnter = () => {},
	onMouseLeave = () => {},
	title = 'Title',
	description = 'Description goes here as always. This is where you will see the description',
	mediaHeight = '160',
	MediaComponent = null,
	categories = [],
	disabled = false,
}) => {
	const theme = useTheme();
	return (
		<Grid xxs={12} sm={6} md={12} lg={6} xl={4}>
			<Card
				elevation={2}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				sx={{
					backgroundColor: theme.palette.primary.surface,
					color: theme.palette.primary.surfaceText,
					border: `1px solid ${theme.palette.primary.surfaceText}44`,
					opacity: disabled ? 0.5 : 1,
				}}
			>
				<CardActionArea
					sx={{ cursor: disabled ? 'default' : 'pointer' }}
				>
					{MediaComponent && (
						<CardMedia
							component={MediaComponent}
							height={mediaHeight}
						/>
					)}
					<CardContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'space-between',
							height: 170,
						}}
					>
						<Typography variant="h4" sx={{ pb: 2, flexGrow: 1 }}>
							{title}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								display: '-webkit-box',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								flexGrow: 0,
								overflow: 'hidden',
							}}
						>
							{description}
						</Typography>
						{categories && (
							<Box sx={{ mt: 2, flexGrow: 1 }}>
								{categories.map((category, index) => (
									<Chip
										key={index}
										variant="outlined"
										label={category}
										size="small"
										sx={{
											mr: 2,
											mb: 2,
										}}
									/>
								))}
							</Box>
						)}
						{disabled && <ComingSoon />}
					</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	);
};

// const ImageCard = ({
// 	onClick = () => {},
// 	mediaHeight = '160',
// 	title = 'Video Card',
// 	description = 'A video card that can be used to highlight an activity',
// 	src = 'https://cdn.remotesocial.io/rs2/connect/video/watercooler.mp4',
// 	poster = 'https://cdn.remotesocial.io/rs2/connect/img/watercooler.gif',
// }) => {};

const VideoCard = ({
	mediaHeight = '160',
	title = null,
	src = null,
	poster = null,
	disabled = false,
	...rest
}) => {
	const videoRef = useRef();
	const handleMouseEnter = () => {
		if (disabled) return;
		videoRef.current.play();
	};
	const handleMouseLeave = () => {
		if (disabled) return;
		videoRef.current.pause();
	};
	const MediaComponent = () => {
		return (
			<>
				{src && (
					<video
						width="100%"
						height={mediaHeight}
						playsInline
						loop
						muted={true}
						poster={poster || null}
						ref={videoRef}
						style={{ objectFit: 'cover' }}
						alt={title}
					>
						<source src={src} type="video/mp4" />
					</video>
				)}
			</>
		);
	};
	return (
		<ActivityCard
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			title={title}
			mediaHeight={mediaHeight}
			MediaComponent={MediaComponent}
			disabled={disabled}
			{...rest}
		/>
	);
};

/** ScheduledActivitiesList -
 * Renders the table of activities data using MaterialUI Tabs and TabPanel
 * components. There are two tabs, one with current (running) activities, and
 * another of past (completed or cancelled) activities.
 *
 * Clicking on a row in an activities table will take the user to the
 * ManageActivity ui where they can view and cancel the activity.
 */
export const ScheduledActivitiesList = ({ activities }) => {
	log('ScheduledActivitiesList', { activities });
	// const { navigateTo } = useRsNavigate();
	const [currentTab, setCurrentTab] = useState(0);
	const currentActivities = activities?.filter((activity) =>
		['scheduled', 'active', 'paused', 'created', 'error'].includes(
			activity.state,
		),
	);
	const pastActivities = activities?.filter((activity) =>
		['cancelled', 'completed'].includes(activity.state),
	);

	if (
		!currentActivities.length &&
		!!pastActivities.length &&
		currentTab !== 1
	) {
		setCurrentTab(1);
	}

	function a11yProps(index) {
		return {
			'id': `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setCurrentTab(newValue);
	};

	log('currentActivities', currentActivities);
	log('pastActivities', pastActivities);

	return (
		<Box sx={{ color: 'inherit' }}>
			<Typography variant="h3" xs={{ mb: 4 }}>
				Your activities
			</Typography>
			<Tabs
				value={currentTab}
				onChange={handleChangeTab}
				aria-label="activities tabs"
				textColor="inherit"
				indicatorColor="primary"
			>
				{!!currentActivities?.length && (
					<Tab label="Current activities" {...a11yProps(0)} />
				)}
				{!!pastActivities?.length && (
					<Tab label="Past activities" {...a11yProps(1)} />
				)}
			</Tabs>
			<TabPanel currentTab={currentTab} index={0}>
				{currentActivities && (
					<ActivitiesData
						type="Current"
						activities={currentActivities}
					/>
				)}
			</TabPanel>
			<TabPanel currentTab={currentTab} index={1}>
				{pastActivities && (
					<ActivitiesData type="Past" activities={pastActivities} />
				)}
			</TabPanel>
		</Box>
	);
};

/** enabled -
 * shows the Connect browse UI with a list of the org's scheduled activities
 * (past and present).
 * This component will link the user through to either the viewActivity ui
 * where they can view and cancel an activity, or to the createActivity ui where
 * the user can schedule a new activity.
 */
export const Enabled = () => {
	// const theme = useTheme();
	const { navigateTo } = useRsNavigate();
	const { activities } = useConnect();
	log('enabled - activities', activities);

	return (
		<Box>
			{!!activities.length && (
				<ScheduledActivitiesList activities={activities} />
			)}
			<Box sx={{ my: 4 }}>
				<Typography variant="h2" sx={{ mt: 8, mb: 4 }}>
					Browse activities
				</Typography>
				<Grid container spacing={4}>
					<VideoCard
						title="Wordle"
						description="Post a regular reminder to play a quick game of Wordle and share the results. Who will be today's Wordle champion?"
						src="https://cdn.remotesocial.io/rs2/connect/video/wordle.mp4"
						poster="https://cdn.remotesocial.io/rs2/connect/img/poster-wordle.png"
						categories={['repeating', 'competitive', 'casual']}
						onClick={() => navigateTo('/connect/wordle')}
					/>
					<VideoCard
						title="Gratituesday"
						description="Encourage participants to share something they are grateful for with this regular Tuesday prompt."
						src="https://cdn.remotesocial.io/rs2/connect/video/gratituesday.mp4"
						poster="https://cdn.remotesocial.io/rs2/connect/img/poster-gratituesday.png"
						categories={['repeating', 'mindfulness', 'wellbeing']}
						onClick={() => navigateTo('/connect/gratituesday')}
					/>
					<VideoCard
						title="Blender"
						description="Introduce random channel members twice a month to connect new people, in person, or remotely."
						src="https://cdn.remotesocial.io/rs2/connect/video/blender.mp4"
						poster="https://cdn.remotesocial.io/rs2/connect/img/poster-blender.png"
						categories={[
							'repeating',
							'team building',
							'icebreaker',
						]}
						onClick={() => {}}
						disabled
					/>
					<VideoCard
						title="Water Cooler"
						description="Start virtual conversations with our twice weekly custom Water Cooler prompts posted to your chosen channel."
						src="https://cdn.remotesocial.io/rs2/connect/video/watercooler.mp4"
						poster="https://cdn.remotesocial.io/rs2/connect/img/poster-watercooler.gif"
						categories={['repeating', 'casual', 'icebreaker']}
						onClick={() => {}}
						disabled
					/>
					<VideoCard
						title="Physical Challenge"
						description="Stay healthy, have fun, and challenge your team with this month-long event promoting regular physical activity."
						src="https://cdn.remotesocial.io/rs2/connect/video/physicalChallenge.mp4"
						poster="https://cdn.remotesocial.io/rs2/connect/img/poster-physical.png"
						categories={['one-off', 'challenge', 'wellbeing']}
						onClick={() => {}}
						disabled
					/>
				</Grid>
			</Box>
		</Box>
	);
};
