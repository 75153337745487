import { useTheme } from '@mui/material/styles';

export default function RsLogoType({ color, fill = true, ...rest }) {
	const theme = useTheme();

	const fillColor = theme.palette.common.white;
	const foreColor = color || 'currentColor';

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1004.62 383"
			{...rest}
		>
			{fill && (
				<g id="RS_Logo_Fill" fill={fillColor}>
					<path d="M160.18,316.91c-2.02,0-4.54-.94-5.56-3.58-8.34-21.64-32.69-64-71.03-85.47l-.11-.06-.11-.05c-9.69-4.42-15.95-14.2-15.95-24.92V123.81c0-10.82,6.33-20.63,16.13-25.01l65.64-29.33c3.48-1.55,7.17-2.34,10.98-2.34s7.49,.79,10.97,2.34l65.72,29.34c9.81,4.38,16.14,14.2,16.14,25.02v79c0,10.33-5.66,19.66-14.77,24.35-36.52,19.04-61.89,58.32-72.52,86.17-1,2.63-3.52,3.57-5.53,3.57h0Z" />
				</g>
			)}
			<g id="logotext" fill={foreColor}>
				<path d="M404.52,180.97h-25.11c-1.32,0-2.31-.5-2.97-1.65l-22.8-37.34h-3.63v36.34c0,1.49-1.16,2.64-2.64,2.64h-23.62c-1.49,0-2.48-1.16-2.48-2.64V67.97c0-1.49,.99-2.64,2.48-2.64h36.35c28.42,0,41.8,15.53,41.8,38.33,0,16.02-6.61,28.58-20.65,34.53l24.95,39.82c1.16,1.82,.33,2.97-1.65,2.97Zm-54.52-64.43h9.42c7.6,0,15.03-1.98,15.03-12.89s-7.43-12.89-15.03-12.89h-9.42v25.77Z" />
				<path d="M423.85,178.32V67.97c0-1.49,.99-2.64,2.48-2.64h60.47c1.49,0,2.48,1.16,2.48,2.64v20.16c0,1.49-.99,2.64-2.48,2.64h-34.2v19.33h32.05c1.49,0,2.64,1.16,2.64,2.64v20.32c0,1.49-1.16,2.48-2.64,2.48h-32.05v19.99h34.03c1.49,0,2.64,1.16,2.64,2.64v20.16c0,1.49-1.16,2.64-2.64,2.64h-60.3c-1.49,0-2.48-1.16-2.48-2.64Z" />
				<path d="M509.76,178.32V67.97c0-1.49,.99-2.64,2.48-2.64h26.6c1.32,0,2.48,.5,2.97,1.65l30.89,80.62,31.06-80.62c.5-1.16,1.49-1.65,2.81-1.65h26.6c1.49,0,2.64,1.16,2.64,2.64v110.36c0,1.49-1.16,2.64-2.64,2.64h-23.62c-1.49,0-2.48-1.16-2.48-2.64v-57.99l-1.98,7.1-19.16,51.38c-.5,1.32-1.65,2.15-2.97,2.15h-20.32c-1.32,0-2.64-.83-3.14-2.15l-19-51.38-1.98-7.1v57.99c0,1.49-1.16,2.64-2.64,2.64h-23.62c-1.49,0-2.48-1.16-2.48-2.64Z" />
				<path d="M654.48,123.64c0-33.54,26.93-59.64,59.64-59.64s59.64,26.1,59.64,59.64-26.93,59.47-59.64,59.47-59.64-26.1-59.64-59.47Zm90.04,0c0-18.01-13.05-31.39-30.4-31.39s-30.56,13.38-30.56,31.39,13.22,31.22,30.56,31.22,30.4-13.38,30.4-31.22Z" />
				<path d="M803.5,178.32V90.76h-21.64c-1.49,0-2.48-1.16-2.48-2.64v-20.16c0-1.49,.99-2.64,2.48-2.64h71.86c1.49,0,2.64,1.16,2.64,2.64v20.16c0,1.49-1.16,2.64-2.64,2.64h-21.48v87.56c0,1.49-1.16,2.64-2.64,2.64h-23.62c-1.49,0-2.48-1.16-2.48-2.64Z" />
				<path d="M875.19,178.32V67.97c0-1.49,.99-2.64,2.48-2.64h60.47c1.49,0,2.48,1.16,2.48,2.64v20.16c0,1.49-.99,2.64-2.48,2.64h-34.2v19.33h32.05c1.49,0,2.64,1.16,2.64,2.64v20.32c0,1.49-1.16,2.48-2.64,2.48h-32.05v19.99h34.03c1.49,0,2.64,1.16,2.64,2.64v20.16c0,1.49-1.16,2.64-2.64,2.64h-60.3c-1.49,0-2.48-1.16-2.48-2.64Z" />
				<path d="M320.42,301.33c-.83-.99-.83-2.31,.17-3.63l12.39-14.04c1.16-1.32,2.31-1.82,3.8-.66,7.93,7.27,13.88,10.57,21.97,10.57,7.27,0,12.72-2.31,12.72-8.92,0-5.62-4.13-7.27-10.57-10.08l-14.04-6.44c-12.23-5.62-24.45-14.37-24.45-33.04,0-22.3,16.85-34.2,37.34-34.2,13.88,0,25.28,5.29,35.35,15.53,.99,.83,1.16,2.31,.17,3.63l-12.22,14.7c-.83,1.32-2.15,1.82-3.47,.99-7.43-5.95-12.72-8.59-18.83-8.59-6.94,0-10.57,3.47-10.57,8.26,0,5.95,4.46,7.27,12.72,11.07l11.23,4.96c12.06,5.62,25.28,13.38,25.28,33.37,0,24.95-20.82,35.02-39.98,35.02s-32.05-9.25-38.99-18.5Z" />
				<path d="M414.59,260.53c0-33.54,26.93-59.64,59.64-59.64s59.64,26.1,59.64,59.64-26.93,59.47-59.64,59.47-59.64-26.1-59.64-59.47Zm90.04,0c0-18.01-13.05-31.39-30.4-31.39s-30.56,13.38-30.56,31.39,13.22,31.22,30.56,31.22,30.4-13.38,30.4-31.22Z" />
				<path d="M549.4,260.53c0-34.2,27.42-59.64,59.97-59.64,16.69,0,30.89,6.77,41.14,17.51,1.16,1.16,.5,2.31-.33,3.3l-14.7,15.69c-.83,1.16-2.31,1.16-3.3,.33-6.11-5.29-13.71-8.59-22.8-8.59-17.51,0-30.89,13.22-30.89,31.39s13.38,31.22,30.89,31.22c9.09,0,16.69-3.3,22.8-8.59,.99-.83,2.48-.83,3.47,.33l14.54,15.69c.83,.83,1.49,1.98,.33,3.14-10.24,10.9-24.45,17.68-41.14,17.68-32.55,0-59.97-25.28-59.97-59.47Z" />
				<path d="M669.01,315.21v-110.36c0-1.49,.99-2.64,2.48-2.64h23.62c1.49,0,2.64,1.16,2.64,2.64v110.36c0,1.49-1.16,2.64-2.64,2.64h-23.62c-1.49,0-2.48-1.16-2.48-2.64Z" />
				<path d="M818.52,317.85h-24.62c-1.32,0-2.48-.5-2.97-1.98l-4.29-13.88h-39.48l-4.29,13.88c-.5,1.49-1.49,1.98-2.81,1.98h-24.62c-1.82,0-2.81-1.16-2.15-2.81l38-110.85c.5-1.32,1.82-1.98,3.14-1.98h24.95c1.32,0,2.81,.66,3.3,1.98l38,110.85c.66,1.65-.33,2.81-2.15,2.81Zm-39.82-41.3l-11.73-37.67-11.73,37.67h23.46Z" />
				<path d="M899.64,294.89v20.49c0,1.49-.99,2.48-2.48,2.48h-58.48c-1.49,0-2.48-1.16-2.48-2.64v-110.36c0-1.49,.99-2.64,2.48-2.64h23.62c1.49,0,2.64,1.16,2.64,2.64v87.56h32.22c1.49,0,2.48,.99,2.48,2.48Z" />
			</g>
			<g id="monogram" fill={foreColor}>
				<path d="M238.08,96.06l-65.72-29.34c-7.77-3.47-16.62-3.46-24.39,0l-65.64,29.33c-10.89,4.87-17.91,15.74-17.91,27.75v79.01c0,11.94,6.94,22.75,17.7,27.65,36.42,20.4,60.84,60.97,69.69,83.94,2.83,7.33,13.89,7.34,16.69,0,8.79-23.04,32.54-64.48,71.09-84.58,10.02-5.16,16.4-15.57,16.4-27.02V123.82c0-12.01-7.03-22.89-17.92-27.76ZM126.98,224.26l-12.62-25.1-6.79-3.38v18.81l-25.02-12.47V127.3l35.73,17.81c20.67,10.3,33.82,27.76,33.82,45.08,0,10.8-5.09,16.38-13.99,16.54l15.59,30.86-26.72-13.32Zm117.87-39.59c-.45,15.9-14.26,34.9-40.99,46-14.41,5.99-28.85,8.42-37.32,6.66l9.97-25.26c7.99,1.47,18.75,.07,28.23-3.87,8.25-3.43,11.26-6.4,11.34-9.38,.31-10.93-47.62,17.69-46.7-14.74,.46-16.15,14.26-34.9,40.99-46,11.58-4.81,23.46-7.29,32.31-5.93l-9.35,24.88c-8.38-.82-16.33,.39-23.84,3.52-8.5,3.53-11.28,7.14-11.37,10.12-.29,10.44,47.63-18.06,46.72,14ZM116.7,163.77l-9.12-4.54v17.53l9.12,4.54c6.89,3.43,10.18,1.76,10.18-3.69s-3.29-10.4-10.18-13.84Z" />
			</g>
		</svg>
	);
}
