import { useTheme } from '@mui/material/styles';
import {
	Box,
	Typography,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	FormHelperText,
	Checkbox,
} from '@mui/material';

const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const getFieldStyles = (color, errorColor, sx) => ({
	'.MuiFormControl-root': {
		'width': '100%',
		'mt': 4,
		'&.Mui-error': {
			color: errorColor.main,
		},
	},
	'.MuiFormGroup-root': {
		width: '100%',
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	'.MuiFormControlLabel-root': {
		m: 0,
	},
	...sx,
});

/**
 * Present a list of checkboxes for each day of the week
 * returns an array of weekdays (['monday', 'wednesday', etc...])
 * that corresponds to the days checked in the checkboxes
 *
 * Days are always in day order with Monday being the first day of the week
 *
 * @return {Array} - an array of weekdays
 */
export const DaysOfWeek = ({
	intro,
	label,
	caption,
	color = 'primary',
	onChange,
	field,
	sx,
	...props
}) => {
	// log('DaysOfWeek - render - field:', field, props);
	const theme = useTheme();
	const themeColor = theme.palette[color];
	const errorColor = color.endsWith('Dark') // if the provided palette ends with 'Dark'
		? theme.palette.errorDark // use the dark error palette
		: theme.palette.error; // otherwise use the regular error palette

	const handleChange = (e) => {
		const { name, checked } = e.target;
		// log('handleChange', name, checked);
		if (checked) {
			onChange(
				DAYS.filter((day) => field.value.includes(day) || day === name),
			);
			return;
		}
		onChange(field.value.filter((day) => day !== name));
	};

	return (
		<Box sx={getFieldStyles(themeColor, errorColor, sx)}>
			{intro && (
				<Typography variant="h4" color={color}>
					{intro}
				</Typography>
			)}
			<FormControl component="fieldset" error={props.error}>
				<FormLabel component="legend">{label}</FormLabel>
				<FormGroup aria-label="days" row>
					{DAYS.map((day) => (
						<FormControlLabel
							key={day}
							name={day}
							value={day}
							control={
								<Checkbox checked={field.value.includes(day)} />
							}
							label={day}
							labelPlacement="bottom"
							onChange={handleChange}
						/>
					))}
				</FormGroup>
				{props.helperText && (
					<FormHelperText>{props.helperText}</FormHelperText>
				)}
			</FormControl>
			{caption && <Typography variant="caption">{caption}</Typography>}
		</Box>
	);
};
