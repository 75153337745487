import { useRsNavigate } from '@common/hooks';
import { RsButton } from '@common/components';
import { Container, Typography, Paper } from '@mui/material';
import { ContentHeader } from '@app/layouts';

export const ComingSoon = () => {
	const { navigateTo } = useRsNavigate();
	const handleBack = () => {
		navigateTo('/');
	};
	const Menu = (
		<RsButton variant="text" onClick={handleBack}>
			back
		</RsButton>
	);
	return (
		<>
			<ContentHeader title="Coming soon" menu={Menu} />
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant="body1">
					Hold tight, this section will be...
				</Typography>
				<Paper sx={{ p: 4, my: 4, display: 'inline-block' }}>
					<img
						alt="coming soon gif"
						src="https://media.giphy.com/media/XhcSIUIkgbmuY/giphy.gif"
						width="100%"
						style={{ borderRadius: '1rem', maxWidth: 360 }}
					/>
					<Typography variant="h3" textAlign="center">
						COMING SOON
					</Typography>
				</Paper>
			</Container>
		</>
	);
};
