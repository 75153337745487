import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@common/firebase';
import {
	useCurrentUser,
	useAsync,
	useRsNavigate,
	useChatApp,
} from '@common/hooks';
import { Loading, ErrorPanel } from '@common/components';
import { useTheme } from '@mui/material/styles';
import { deleteCookie, readCookie, textTools } from '@common/utils';

export const SlackRedirect = () => {
	const theme = useTheme();
	const { navigateTo } = useRsNavigate();
	const { config } = useChatApp();
	const { loading: userLoading, user } = useCurrentUser();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState(false);
	const [validatedState, setValidatedState] = useState(null);
	const [validatedUser, setValidatedUser] = useState(null);
	const exchangeCode = httpsCallable(functions, 'platform-chats-slack-oauth');
	const [exchangeAuthCode, triggerExchangeAuthCode] = useAsync(exchangeCode);

	/** fetch the state and code params from the URL search string */
	const state = searchParams.get('state');
	const code = searchParams.get('code');
	const cookie = readCookie('slackConnectState');
	const { redirect_uri } = config;

	/**
	 * In order to verify this callback response from Slack we need to check:
	 * 1. There is a code and state param supplied.
	 * 2. The state param matches the secure state cookie stored on the client.
	 * 3. Extract the uid and orgId from the state param.
	 * 3. If the uid does not match, we need to refuse the request with an error
	 * 4. If the uid matches, but the orgId does not, we need to check the orgs
	 * and potentially shift the user into the correct orgId, or show an error.
	 * 5. Finally we need to process the code and state params by sending to the
	 * server to be exchanged for an access token that will be stored on the
	 * currentOrg property within the org record.
	 */

	/** onInit - check the state param matches stored state param */
	useEffect(() => {
		if (validatedState) return; // state has already been validated

		log('validating state...', { state, cookie });

		if (!state) return setError('Missing state param');
		if (!cookie) return setError('Missing stored state');
		if (state !== cookie) {
			setError('Provided State does not match stored state');
			return deleteCookie('slackConnectState'); // delete the stored state
		}

		/** state = cookie - set state as validated */
		setValidatedState(state);
		/** delete the state cookie, we no longer need it */
		deleteCookie('slackConnectState');
	}, [state, validatedState, cookie]);

	/** once state has been validated as matching - validate the user params */
	useEffect(() => {
		if (validatedUser) return; // user has already been validated
		if (!validatedState || userLoading) return; // wait for state validation

		log('validating user...', { validatedState, user });

		if (!user) return setError('Missing user');
		const [uid, orgId] = textTools.decode(validatedState).split(':');
		if (uid !== user.uid) return setError('Mismatching userId');
		if (orgId !== user.currentOrg.id) return setError('Mismatching orgId');

		/** set user as validated */
		setValidatedUser({ uid, orgId });
	}, [validatedUser, validatedState, userLoading, user]);

	/** once state has been validated as matching */
	useEffect(() => {
		// wait for state and user data to be validated as correct
		if (!validatedUser) return;
		const { loading, error, response } = exchangeAuthCode;
		if (loading) return;
		if (error) {
			console.error('exchangeAuthCode.error:', error);
			return setError(error.message);
		}
		if (response) {
			log('exchangeAuthCode.response: ', response);
			return navigateTo('/connect');
		}

		/** exchangeAuthCode has not been called yet */
		const { uid, orgId } = validatedUser;

		log('exchanging auth code...', { code });

		/** check error states */
		if (!code) return setError('Missing "code" param');
		if (!redirect_uri) setError('Missing config.redirect_uri');

		/**
		 * code and state params are present and valid we can
		 * forward the code to the server to be exchanged for an action token
		 */
		/** don't call the api if it's already in progress or completed */
		if (!exchangeAuthCode.loading && !exchangeAuthCode.response) {
			triggerExchangeAuthCode({
				code,
				userIdOrgId: `${uid}:${orgId}`,
				redirect_uri: redirect_uri,
			});
		}
	}, [
		validatedUser,
		code,
		redirect_uri,
		exchangeAuthCode,
		navigateTo,
		triggerExchangeAuthCode,
	]);

	return (
		<>
			{error ? (
				<ErrorPanel error={error} redirect={`/connect`} />
			) : (
				<Loading
					showRandom={true}
					label="Connecting to Slack..."
					color={theme.palette.primary.surface}
				/>
			)}
		</>
	);
};
