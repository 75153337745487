import { useChatApp, useCurrentUser } from '@common/hooks';
import { createCookie, createRandomString, textTools } from '@common/utils';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Sync } from '@mui/icons-material';

/** createState - create a random state value and store on the client
 * as a secure HTML only cookie. This state value will be used to verify the
 * returned state value from the OAuth2 server.
 */
const createState = (uid, orgId) => {
	const randomString = createRandomString(24);
	const state = `${uid}:${orgId}:${randomString}`;
	const b64 = textTools.encode(state);
	return b64;
};

export const ReauthSlackButton = ({ theme, reAuthRequired }) => {
	const { config } = useChatApp();
	const { user } = useCurrentUser();

	if (!user || !user.uid || !user.currentOrg.orgId) return null;

	/**
	 * constructs a state value from the userId, orgId, and a random string
	 * stores the state value in a secure session cookie for later verification
	 * redirects the user to Slack to authorize the app
	 */
	const handleClick = () => {
		const userId = user?.uid;
		const orgId = user?.currentOrg?.orgId;
		// create a state value including the uid and orgId and random string
		const state = createState(userId, orgId);
		// store the state value in a secure session cookie - no expiry date
		createCookie('slackConnectState', state, null, 1 / 12);
		// append the state value to the oauth_url
		const url = `${config.oauth_url}&state=${state}`;
		// redirect the user to the oauth_url
		window.location.replace(url);
	};

	return (
		<ListItemButton
			divider
			onClick={handleClick}
			sx={{
				backgroundColor: reAuthRequired
					? theme.palette.error.surface
					: 'transparent',
				color: reAuthRequired
					? theme.palette.error.main
					: theme.palette.common.light,
			}}
		>
			<ListItemIcon>
				<Sync />
			</ListItemIcon>
			<ListItemText
				primary="Re-Authenticate"
				secondary="Update permissions"
			/>
		</ListItemButton>
	);
};
