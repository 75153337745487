import { Outlet } from 'react-router-dom';
import { GlobalHeader, GlobalFooter } from '@common/components';
import { Container, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const FullScreenLayout = ({ children }) => {
	const theme = useTheme();
	// const { setAppStyles } = useAppSettingsUpdater();
	// const randomBackground = useMemo(() => {
	// 	// create a random background only once and reuse each render
	// 	const TEXTURES = [
	// 		'asfalt-dark.png',
	// 		'bright-squares.png',
	// 		'diamond-upholstery.png',
	// 		'inspiration-geometry.png',
	// 		'hexellence.png',
	// 		'padded-light.png',
	// 	];
	// 	const TEXTURE_URL = 'https://cdn.remotesocial.io/common/img/textures/';
	// 	const randomTexture =
	// 		TEXTURES[Math.floor(Math.random() * TEXTURES.length)];
	// 	const background = [
	// 		`url('${TEXTURE_URL + randomTexture}') center center repeat`,
	// 		`${theme.palette.primary.gradient}`,
	// 	].join(',');
	// 	return {
	// 		body: {
	// 			background: background,
	// 		},
	// 	};
	// }, [theme]);

	// useEffect(() => {
	// 	setAppStyles(randomBackground);
	// }, [setAppStyles, randomBackground]);

	return (
		<Box>
			<GlobalHeader />
			<Container
				fixed
				sx={{
					minHeight: 'calc(100vh - 7rem)',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					py: 8,
				}}
			>
				{children ? children : <Outlet />}
			</Container>
			<GlobalFooter color={theme.palette.secondary} />
		</Box>
	);
};
