import { Link } from 'react-router-dom';
import { Container, Paper, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getUrlByEnvironment } from '@common/utils';

export const ErrorPanel = ({ error, redirect }) => {
	const theme = useTheme();

	console.log(error);
	return (
		<Container maxWidth="sm">
			<Paper
				elevation={5}
				sx={{
					p: 4,
					backgroundColor: theme.palette.primary.surface,
					border: `2px solid ${theme.palette.error.light}`,
					color: theme.palette.error.containerText,
					textAlign: 'center',
				}}
			>
				<Typography variant="h1">🚨 Oops 🚨</Typography>
				<Typography variant="body1">
					{error} - {error.details}
				</Typography>
				<Button
					variant="outlined"
					component={Link}
					to={redirect || getUrlByEnvironment('app')}
					color="error"
				>
					Back
				</Button>
			</Paper>
		</Container>
	);
};
