/** useFirestoreQuery */
import { useReducer, useCallback, useRef } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from '@common/firebase';
import Joi from 'joi';

/**
 * React hook to listen to a firestore collection with a query and returns
 * the array of matching documents data
 *
 * Whenever any document data covered by the query is updated within Firestore
 * this hook will trigger a re-render of the data and therefore
 * any subscribed components.
 *
 * This is accomplished by using the onSnapshot method of the firestore document
 *
 * The listen function requires a path parameter which can be either a string or
 * an array in the form of ['collection', 'doc', 'collection', ...]
 * The path array must have an odd number of elements to point to a collection.
 *
 * The listen function returns an unsubscribe callback function that can be used
 * to cancel the subscription. This should be used in the useEffect cleanup.
 *
 * Ideally the unsubscribe function will be called on a useEffect that only runs
 * once when the component is mounted. This will ensure that the subscription
 * is only active for the lifetime of the component.
 *
 * useEffect(() => {
 * 	 const unsubscribe = listenFunc('path/to/collection');
 * 	 return unsubscribe;
 * }, []); <-- empty array ensures this only runs once during component mount
 *
 * @returns {Array} [state, listen]
 *
 * @return {Object} state - firestore query state object
 * @param {Boolean} state.loading - has the document loaded successfully
 * @param {Error} state.error - error object if there was an error
 * @param {Array} state.data - Array of DocumentSnapshot objects
 *
 * @return {Function} listen - trigger listening for document updates
 * @param {string} listen.path - path to the document to listen to
 *
 */
export const useFirestoreQuery = () => {
	const caller = useRef(
		new Error().stack.split('\n')[2].trim().split(' ')[1],
	);
	/** query parameters including collectionPath and query params */
	const queryPath = useRef(null);
	const queryParams = useRef(null);

	/** placeholder for the reused unsubscribe callback function */
	const unsubscribe = useRef(() => {
		log(caller, 'unsubscribe called on empty:', queryPath.current);
	});

	/** state and reducer for useReducer hook */
	const initialState = {
		loading: true,
		data: [],
	};
	const reducer = (state, action) => {
		switch (action.type) {
			case 'loading':
				return {
					...state,
					loading: true,
				};
			case 'empty':
				return {
					loading: false,
					data: [],
				};
			case 'update':
				return {
					loading: false,
					data: action.payload,
				};
			case 'error':
				const error = action.payload;
				if (error.code === 'permission-denied') {
					error.message =
						'Firestore Rules permission error: ' +
						error.message.slice(1);
				}
				console.error(
					'🔥📄 useFirestoreQuery - ❌ Error:',
					error.message,
					{
						message: error.message,
						path: queryPath.current,
						caller: caller.current,
						error,
					},
				);
				return {
					loading: false,
					data: [],
				};
			default:
				return state;
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	/**
	 * The path param can be either 'a/slash/separated/string' or an Array.
	 * The path must have an odd number of components, as it is assumed that
	 * the path is in the format of ['collection', 'doc', 'collection', ...]
	 * @callback listen
	 * @param {String} collectionPath - path to the collection to query
	 * @param {Array} queryParams - array of query parameters
	 * @returns {Function} unsubscribe - the unsubscribe function
	 */
	const listen = useCallback((path, params) => {
		try {
			/** validate collection path and store */
			/**
			 * pattern validates the string contains nil or an
			 * even number of '/' and that each segment has min 3 chars.
			 * 'col/doc/col' is valid
			 * 'col' is valid
			 * 'col/doc' is invalid
			 */
			const schema = Joi.object({
				path: Joi.string()
					.required()
					.pattern(/^([^/]{3,}(\/[^/]{3,}\/)+)*[^/]{3,}$/),
				params: Joi.array().required().min(3),
			}).required();
			const { error } = schema.validate(
				{ path, params },
				{ abortEarly: false },
			);
			if (error) throw error;

			/** check we aren't already listening to the same path & query */
			if (path === queryPath.current && params === queryParams.current) {
				log(
					'useFirestoreQuery - already subscribed to:',
					path,
					'with query:',
					params,
				);
				return;
			}

			/** set the path and query values for later checking */
			queryPath.current = path;
			queryParams.current = params;

			/** start the collectionQuery listener */
			const collectionRef = collection(firestore, path);
			const q = query(collectionRef, where(...params));
			unsubscribe.current = onSnapshot(q, (querySnapshot) => {
				// log('querySnapshot', querySnapshot, querySnapshot.empty);
				/** if there are no results - return empty */
				if (querySnapshot.empty) {
					dispatch({ type: 'empty' });
					return;
				}
				const documents = [];
				querySnapshot.forEach((snapshot) => {
					documents.push({
						id: snapshot.id,
						_documentPath: snapshot.ref.path, // add the doc path
						...snapshot.data(),
					});
				});
				/** return the documents array */
				dispatch({ type: 'update', payload: documents });
			});
			dispatch({ type: 'loading' });
		} catch (e) {
			/** set error state */
			console.error('useFirestoreQuery - error', e.message, e);
			dispatch({ type: 'error', payload: e });
		} finally {
			return () => {
				log(
					'useFirestoreQuery - unsubscribing from:',
					queryPath.current,
				);
				unsubscribe.current();
			};
		}
	}, []);

	return [state, listen];
};
