import { getEnvironment } from '@common/utils';

const config = {
	staging: {
		apiKey: 'AIzaSyBI7ChJoP_IWjxaQJp8b8r9JG2t8scqHBY',
		authDomain: 'rs2-dev.firebaseapp.com',
		databaseURL: 'https://rs2-dev-default-rtdb.firebaseio.com',
		projectId: 'rs2-dev',
		storageBucket: 'rs2-dev.appspot.com',
		messagingSenderId: '862680845530',
		appId: '1:862680845530:web:71cee7d90e4283fa11e9e3',
		measurementId: 'G-J914DFJE50',
	},
	production: {
		apiKey: 'AIzaSyA-xD_C7MOwQE5Q0FCKJxtP76ywtPC5OYU',
		authDomain: 'rs2-prod.firebaseapp.com',
		databaseURL: 'https://rs2-prod-default-rtdb.firebaseio.com',
		projectId: 'rs2-prod',
		storageBucket: 'rs2-prod.appspot.com',
		messagingSenderId: '602120408550',
		appId: '1:602120408550:web:9d4b5a14fe2a2498c89a27',
		measurementId: 'G-XRDKJKDKXF',
	},
};

export const getFirebaseConfig = () => {
	const env = getEnvironment();

	if (env === 'production') {
		return config.production;
	}

	return config.staging;
};
