import { forwardRef } from 'react';

import { useTheme } from '@mui/material/styles';
import {
	Box,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
} from '@mui/material';
import { AddBusiness, Apartment, Groups } from '@mui/icons-material';
import { Avatar } from '@mui/material';

export const OrgProfileBadge = forwardRef(
	({ org = {}, width = '95%', sx, extended = false, ...rest }, ref) => {
		const theme = useTheme();
		return (
			<Box sx={{ width: width }}>
				<List
					sx={{
						width: '100%',
						color: theme.palette.primary.main,
						borderRadius: '0.6rem',
					}}
				>
					<ListItem
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								width: '8rem',
								height: '8rem',
								borderRadius: '0.4rem',
								border: '2px solid',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Avatar
								className="orgAvatar"
								ref={ref}
								alt={org.name || 'Organisation Avatar'}
								src={
									org.photoURL
										? org.photoURL
										: `https://logo.clearbit.com/${org.domain}`
								}
								variant="rounded"
								sx={{
									m: '0.5rem',
									width: '6.5rem',
									height: '6.5rem',
									backgroundColor:
										theme.palette.primary.contrastText,
								}}
								{...rest}
							>
								<AddBusiness
									sx={{
										width: '5rem',
										height: '5rem',
										color: theme.palette.primary.main,
									}}
								/>
							</Avatar>
						</Box>
					</ListItem>
					<ListItem
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Typography variant="h5" sx={{ textAlign: 'center' }}>
							{org.name || 'New Org'}
						</Typography>
						<Divider />
					</ListItem>
					{extended && (
						<>
							{org.size >= 0 && (
								<ListItem>
									<ListItemIcon sx={{ color: 'inherit' }}>
										<Groups />
									</ListItemIcon>
									<ListItemText primary="Team size" />
								</ListItem>
							)}
							{org.industry !== 'not specified' && (
								<ListItem>
									<ListItemIcon sx={{ color: 'inherit' }}>
										<Apartment />
									</ListItemIcon>
									<ListItemText primary="Industry" />
								</ListItem>
							)}
						</>
					)}
				</List>
				{!org.photoURL && org.domain && (
					<Typography
						variant="caption"
						sx={{
							mt: 4,
							width: '100%',
							display: 'block',
							textAlign: 'center',
							color: theme.palette.neutral.light,
						}}
					>
						Company Logos provided by{' '}
						<a href="https://clearbit.com" target="blank">
							Clearbit
						</a>
					</Typography>
				)}
			</Box>
		);
	},
);
