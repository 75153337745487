/** handle display of the Wordle Connect game */
import { useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
	LoadingButton as Button,
	RsWizard,
	RsButton,
} from '@common/components';
import { useAsync, useRsNavigate } from '@common/hooks';
import { Container, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ContentHeader } from '@app/layouts';
import {
	useConnect,
	useSelectChannel,
	useSelectStart,
	useSelectFrequency,
} from '@app/hooks/connect';

export const Wordle = () => {
	const { navigateTo } = useRsNavigate();

	const handleBack = () => {
		navigateTo('/connect');
	};

	const handleCreate = () => {
		navigateTo('/connect/wordle/create');
	};

	const HeaderContent = (
		<>
			<Typography variant="h5" sx={{ mb: 4 }}>
				Wordle, the hit word game that took the world by storm.
				<br />
				Guess a five letter word each day in under six attempts.
			</Typography>
			<Typography variant="body1">
				We post a daily link to the puzzle in your chosen channel, and
				ask players to share their results with the group.
			</Typography>
		</>
	);

	const Menu = (
		<>
			{/* <RsButton variant="colored" onClick={handleCreate}>
				Create Activity
			</RsButton> */}
			<RsButton variant="text" onClick={handleBack}>
				Back
			</RsButton>
		</>
	);

	return (
		<Box>
			<ContentHeader title="Wordle" menu={Menu} content={HeaderContent} />
			<Grid container sx={{ mt: 12 }}>
				<Grid xs={12} sm={4} sx={{ textAlign: 'center' }}>
					<img
						src="https://cdn.remotesocial.io/rs2/connect/wordle/wordle-title.png"
						width="200px"
						height="auto"
						style={{
							border: '4px solid #FFF',
							borderRadius: '1rem',
							boxShadow: '0 0 0.5rem rgba(87,3,200,0.5)',
							transform: 'rotate(-2deg) translate(0, 0)',
						}}
						alt="Wordle title"
					/>
				</Grid>
				<Grid xs={12} sm={8}>
					<Typography variant="body1" sx={{ mb: 4 }}>
						Wordle encourages players to guess a five-letter word in
						under 6 guesses with clues provided at each step. By
						sharing their daily results with the group, func
						conversations and some competitive banter can start
						within the team.
					</Typography>
					<Typography variant="body1">
						Requires only a few minutes of time each day.
					</Typography>
				</Grid>
			</Grid>
			<Container sx={{ my: 8, textAlign: 'center' }}>
				<RsButton
					variant="contained"
					color="primary"
					size="large"
					onClick={handleCreate}
					sx={{ mr: 2 }}
				>
					Set Up Wordle
				</RsButton>
			</Container>
			<Container maxWidth="xs" sx={{ my: 10 }}>
				<Typography variant="h4" sx={{ mb: 4 }}>
					Our Recommended Settings
				</Typography>
				<hr />
				<ul>
					<li>Post the puzzle link each working day: Mon - Fri</li>
					<li>Set start time in the morning your local time</li>
					<li>Add to an existing social channel in your workspace</li>
				</ul>
			</Container>
			<Container maxWidth="xs" sx={{ my: 10 }}>
				<img
					src="https://cdn.remotesocial.io/rs2/connect/img/wordle-sample.png"
					width="100%"
					height="auto"
					style={{
						border: '4px solid #FFF',
						borderRadius: '1rem',
						boxShadow: '0 0 0.5rem rgba(87,3,200,0.5)',
					}}
					alt="Wordle sample"
				/>
			</Container>
			<Container sx={{ my: 8, textAlign: 'center' }}>
				<RsButton
					variant="colored"
					size="large"
					onClick={handleCreate}
					sx={{ mr: 2 }}
				>
					Set Up Wordle
				</RsButton>
			</Container>
		</Box>
	);
};

export const WordleCreate = () => {
	const formikBagRef = useRef(null);
	const { navigateTo } = useRsNavigate();
	const { createActivity } = useConnect();

	const [createWordle, triggerCreateWordle] = useAsync(createActivity);

	const onReset = (values, formikBag) => {
		log('WordleCreate - onReset', values);
		navigateTo('/connect/wordle');
	};

	const onSubmit = async (values, formikBag) => {
		log('onSubmit', values);
		formikBagRef.current = formikBag;
		await triggerCreateWordle('wordle', values);
	};

	const handleServerError = useCallback((error) => {
		log('handleServerError', error);
		const formikBag = formikBagRef.current;
		if (formikBag) {
			formikBag.setErrors(error.details || { server: error.message });
			formikBag.setStatus({
				state: 'error',
				message: error.message,
			});
			formikBag.setSubmitting(false);
		}
	}, []);

	const handleServerResponse = useCallback(
		(response) => {
			log('handleServerResponse', response);
			const formikBag = formikBagRef.current;
			if (formikBag) {
				formikBag.setStatus({
					state: 'success',
					message: `Activity created: ${response}`,
				});
				formikBag.setSubmitting(false);
			}
			navigateTo(`/connect/activity/${response}`);
		},
		[navigateTo],
	);

	useEffect(() => {
		// const { loading, error, response } = createWordle;
		// if (loading) formikBagRef.current.setSubmitting(true);
		const { error, response } = createWordle;
		if (error) return handleServerError(error);
		if (response) return handleServerResponse(response);
	}, [createWordle, handleServerError, handleServerResponse]);

	const step1 = useSelectChannel({
		preferCreate: true,
		preamble: '',
	});
	const step2 = useSelectStart({
		preamble: '',
	});
	const step3 = useSelectFrequency({
		repeat: 'weekly',
		initialValues: {
			daysOfWeek: ['mon', 'tue', 'wed', 'thu', 'fri'],
		},
		preamble:
			'Select the days of the week below that you would like us to post a Wordle prompt to the channel.',
	});

	const Menu = () => {
		return (
			<>
				<RsButton variant="text" onClick={onReset}>
					Back
				</RsButton>
			</>
		);
	};

	return (
		<>
			<ContentHeader title="Wordle Create" menu={<Menu />} />
			<Container>
				<RsWizard
					steps={[step1, step2, step3]}
					formikConfig={{
						validateOnBlur: true,
						validateOnChange: true,
						validateOnMount: false,
						onSubmit: onSubmit,
						onReset: onReset,
					}}
				/>
			</Container>
		</>
	);
};

export const WordleActivity = () => {
	const { activityId } = useParams();
	const handleEdit = () => {
		log('edit');
	};
	const EditButton = () => {
		return <Button>Edit</Button>;
	};
	return (
		<>
			<ContentHeader
				title="Wordle Activity"
				menu={<EditButton onClick={handleEdit} />}
			/>
			<Container>
				<h1>Wordle activity: {activityId}</h1>
			</Container>
		</>
	);
};
