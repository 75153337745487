import { useCurrentUser, useChatApp } from '@common/hooks';
import { textTools } from '@common/utils';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import {
	Avatar,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItemAvatar,
	Box,
	Divider,
	Drawer,
	IconButton,
	Toolbar,
} from '@mui/material';
import { RsOrgAvatar } from '@common/components';
import { Slack } from '@common/assets/images/Slack';
import {
	Diversity1,
	SportsEsports,
	Group,
	Insights,
	Settings,
	Payment,
	Close,
} from '@mui/icons-material';

export const DashboardMenu = ({ variant = 'dark', handleDrawerClose }) => {
	handleDrawerClose = handleDrawerClose || (() => {});
	const theme = useTheme();
	// const parentTheme = useTheme();
	// const theme = makeModeTheme(parentTheme, variant, {
	// 	primary: 'rsPurple',
	// 	secondary: 'rsPink',
	// });
	const { user } = useCurrentUser();
	const { team } = useChatApp();

	const org = user?.currentOrg;

	const transparent = theme.palette.makeTransparent;

	const colors = {
		text: theme.palette.primary.surfaceText,
		subtext: transparent(theme.palette.primary.surfaceText, 0.7),
		hoverBg: transparent(theme.palette.primary.surfaceText, 0.1),
		activeBg: transparent(theme.palette.primary.surfaceText, 0.2),
		hrColor: transparent(theme.palette.primary.surfaceText, 0.2),
	};

	const handleNavSelect = () => {
		handleDrawerClose();
	};

	const listStyles = {
		'p': 0,
		'.MuiListItemButton-root': {
			'color': colors.text,
			'&:hover': {
				backgroundColor: colors.hoverBg,
			},
			'&.active': {
				backgroundColor: colors.activeBg,
			},
			'&.orgAvatar': {
				'&.active': {
					'backgroundColor': 'transparent',
					'&:hover': {
						backgroundColor: colors.hoverBg,
					},
				},
			},
		},
		'*.MuiListItemText-primary': {
			fontSize: '0.95rem',
			fontWeight: 'bold',
		},
		'*.MuiListItemText-secondary': {
			fontSize: '0.8rem',
			color: colors.subtext,
		},
		'*.MuiListItemIcon-root': {
			color: colors.text,
			ml: 2,
			mr: -2,
		},
		'*.MuiDivider-root': {
			borderColor: colors.hrColor,
		},
	};

	return (
		<List sx={{ width: '100%', pt: 0, ...listStyles }}>
			{org && (
				<>
					<ListItemButton
						className="orgAvatar"
						sx={{ textAlign: 'center', height: '12rem' }}
						component={NavLink}
						to="/"
						onClick={handleNavSelect}
					>
						<Box sx={{ mx: 'auto', my: 1 }}>
							<RsOrgAvatar
								org={org}
								title={org.name}
								variant="badge"
								size="8rem"
							/>
						</Box>
					</ListItemButton>
					<Divider />
				</>
			)}

			<ListItemButton
				component={NavLink}
				to="/connect"
				onClick={handleNavSelect}
			>
				<ListItemAvatar>
					<Avatar
						sx={{ backgroundColor: theme.palette.common.white }}
					>
						<Slack size="2rem" />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={
						team?.chatId
							? textTools.capitalize(team?.chatId)
							: 'Connect'
					}
					secondary={team?.chatId ? 'Connected' : 'Slack or MS Teams'}
				/>
			</ListItemButton>

			<ListItemButton
				component={NavLink}
				to="/toolkit"
				onClick={handleNavSelect}
				disabled
			>
				<ListItemAvatar>
					<Avatar sx={{ backgroundColor: theme.palette.rsTeal.main }}>
						<Diversity1 />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary="Team Toolkit"
					// secondary="Empathy & connection"
					secondary="Coming soon"
				/>
			</ListItemButton>
			<ListItemButton
				component={NavLink}
				to="/games"
				onClick={handleNavSelect}
				disabled
			>
				<ListItemAvatar>
					<Avatar sx={{ backgroundColor: theme.palette.rsBlue.main }}>
						<SportsEsports />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary="Games & Activities"
					// secondary="Team social events"
					secondary="Coming soon"
				/>
			</ListItemButton>
			{/* <ListItem
				button
				divider
				
				component={NavLink}
				to="/hosted"
				onClick={handleNavSelect}
			>
				<ListItemAvatar>
					<Avatar
						sx={{ backgroundColor: theme.palette.rsPurple.main }}
					>
						<VideoCameraFront />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary="Hosted Experiences"
					secondary="Curated Marketplace"
				/>
			</ListItem> */}
			<Divider />
			<ListItemButton
				component={NavLink}
				to="/people"
				onClick={handleNavSelect}
				disabled
			>
				<ListItemIcon>
					<Group />
				</ListItemIcon>
				People
			</ListItemButton>
			<ListItemButton
				component={NavLink}
				to="/analytics"
				onClick={handleNavSelect}
				disabled
			>
				<ListItemIcon>
					<Insights />
				</ListItemIcon>
				Analytics
			</ListItemButton>
			<ListItemButton
				component={NavLink}
				to="/billing"
				onClick={handleNavSelect}
				disabled
			>
				<ListItemIcon>
					<Payment />
				</ListItemIcon>
				Billing
			</ListItemButton>
			<ListItemButton
				component={NavLink}
				to="/settings"
				onClick={handleNavSelect}
				disabled
			>
				<ListItemIcon>
					<Settings />
				</ListItemIcon>
				Settings
			</ListItemButton>
		</List>
	);
};

export const DashboardDrawer = ({
	isTablet,
	drawerOpen,
	handleDrawerClose,
}) => {
	const theme = useTheme();
	const drawerProps = {
		PaperProps: {
			sx: {
				boxSizing: 'border-box',
				width: '15rem',
				background: theme.palette.primary.surface,
				color: theme.palette.primary.surfaceText,
			},
		},
	};

	// set the drawerProps if the layout is in tablet mode
	if (isTablet) {
		drawerProps.open = drawerOpen;
		drawerProps.onClose = handleDrawerClose;
		drawerProps.variant = 'temporary';
	} else {
		drawerProps.variant = 'permanent';
	}

	return (
		<Drawer sx={{ display: { sm: 'block' } }} {...drawerProps}>
			<Box component="nav" sx={{}}>
				{isTablet && (
					<>
						<Toolbar sx={{ minHeight: '4rem' }}>
							<IconButton
								aria-label="close"
								onClick={handleDrawerClose}
								sx={{
									position: 'absolute',
									left: 8,
									top: 8,
									color: 'primary.dark',
								}}
							>
								<Close />
							</IconButton>
						</Toolbar>
					</>
				)}
				<DashboardMenu
					theme={theme}
					handleDrawerClose={handleDrawerClose}
				/>
			</Box>
		</Drawer>
	);
};
