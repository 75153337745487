import { useTheme } from '@mui/material/styles';
import { makeModeTheme } from '@common/themes';

export const useAppTheme = () => {
	const theme = useTheme();
	const appTheme = makeModeTheme(theme, 'light', {
		primary: 'rsPurple',
		secondary: 'rsPink',
		tertiary: 'rsTeal',
	});
	return appTheme;
};

export const orgConfigOptions = {
	domainBlacklist: [
		'gmail',
		'yahoo',
		'me',
		'hotmail',
		'outlook',
		'live',
		'aol',
		'icloud',
		'mail',
		'protonmail',
		'zoho',
		'yandex',
		'gmx',
		'mail',
		'inbox',
		'fastmail',
		'tutanota',
		'hushmail',
		'posteo',
	],
	industries: [
		'Not specified',
		'Accommodation and Food Services',
		'Administrative and Support Services',
		'Agriculture, Forestry and Fishing',
		'Arts and Recreation Services',
		'Construction',
		'Consulting',
		'Education and Training',
		'Electricity, Gas, Water and Waste Services',
		'Financial and Insurance Services',
		'Health Care and Social Assistance',
		'Information Media and Telecommunications',
		'Manufacturing',
		'Mining',
		'Personal Services',
		'Professional, Scientific and Technical Services',
		'Public Administration and Safety',
		'Rental, Hiring and Real Estate Services',
		'Retail Trade',
		'Technology/SaaS',
		'Transport, Postal and Warehousing',
		'Wholesale Trade',
		'Other',
	],
	departments: [
		'Not specified',
		'Accounting',
		'Administration',
		'Business Development',
		'Customer Service',
		'Engineering',
		'Finance',
		'Information Technology',
		'Legal',
		'Management',
		'Marketing',
		'Operations',
		'People & Culture/HR',
		'Product Management',
		'Purchasing',
		'Quality Assurance',
		'Research and Development',
		'Sales',
		'Support',
		'Training',
		'Other',
	],
	sizes: [
		{
			value: 0,
			label: '',
			text: '?',
		},
		{
			value: 1,
			label: '1-24',
			text: 'Small',
		},
		{
			value: 2,
			label: '25-99',
			text: 'Medium',
		},
		{
			value: 3,
			label: '100-499',
			text: 'Large',
		},
		{
			value: 4,
			label: '500+',
			text: 'Enterprise',
		},
	],
	timezones: Intl.supportedValuesOf('timeZone'),
};

// the following list of industries was generated by CoPilot, and may be
// useful someday if we need a bigger list than the one above.
export const COMMON_INDUSTRIES = [
	'Not specified',
	'Accounting',
	'Advertising',
	'Aerospace',
	'Agribusiness',
	'Airline',
	'Apparel',
	'Automotive',
	'Banking',
	'Biotechnology',
	'Broadcasting',
	'Brokerage',
	'Chemical',
	'Computer',
	'Construction',
	'Consulting',
	'Consumer',
	'Cosmetics',
	'Defense',
	'Department',
	'Drug',
	'Education',
	'Electronics',
	'Energy',
	'Entertainment',
	'Environmental',
	'Financial',
	'Food',
	'Furniture',
	'Government',
	'Healthcare',
	'Insurance',
	'Internet',
	'Investment',
	'Legal',
	'Manufacturing',
	'Media',
	'Mining',
	'Mortgage',
	'Nonprofit',
	'Oil',
	'Pharmaceutical',
	'Publishing',
	'Real Estate',
	'Recruitment',
	'Retail',
	'Software',
	'Sports',
	'Telecommunications',
	'Transportation',
	'Travel',
	'Utilities',
	'Venture Capital',
	'Wholesale',
	'Other',
];
