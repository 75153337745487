export const Slack = ({ size, color, style }) => {
	size ??= '2.5rem';

	const color0 = '#E01E5A';
	const color1 = '#36C5F0';
	const color2 = '#2EB67D';
	const color3 = '#ECB22E';

	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			height={size}
			width={size}
			x="0px"
			y="0px"
			viewBox="0 0 150 150"
			style={{ ...style }}
		>
			<g>
				<g>
					<path
						fill={color || color0}
						d="M39.4,91.2c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V91.2z"
					/>
					<path
						fill={color || color0}
						d="M45.9,91.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9
					C45.9,123.5,45.9,91.2,45.9,91.2z"
					/>
				</g>
				<g>
					<path
						fill={color || color1}
						d="M58.8,39.4c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H58.8z"
					/>
					<path
						fill={color || color1}
						d="M58.8,45.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H26.5c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9
					C26.5,45.9,58.8,45.9,58.8,45.9z"
					/>
				</g>
				<g>
					<path
						fill={color || color2}
						d="M110.6,58.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V58.8z"
					/>
					<path
						fill={color || color2}
						d="M104.1,58.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V26.5c0-7.1,5.8-12.9,12.9-12.9
					c7.1,0,12.9,5.8,12.9,12.9V58.8z"
					/>
				</g>
				<g>
					<path
						fill={color || color3}
						d="M91.2,110.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H91.2z"
					/>
					<path
						fill={color || color3}
						d="M91.2,104.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9
					c0,7.1-5.8,12.9-12.9,12.9H91.2z"
					/>
				</g>
			</g>
		</svg>
	);
};
