import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { LoadingButton, RsButton, Loading } from '@common/components';
import { getEnvironment } from '@common/utils/environment';

import { useAsync, useRsNavigate, useFirestoreDoc } from '@common/hooks';
import { useConnect } from '@app/hooks/connect';
import { ContentHeader } from '@app/layouts';
import { textTools } from '@common/utils';
import {
	Card,
	CardContent,
	CardMedia,
	Chip,
	Container,
	Divider,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	// TableHead,
	TableRow,
	Typography,
} from '@mui/material';
// import { Cancel, Tag } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

// states the activity can be in where it will not process events
// const INACTIVE_STATES = [
// 	'cancelled', // activity cancelled and should not process any events
// 	'error', // an error occurred and the activity should not process any event
// 	'completed', // activity has completed and should not process events
// ];
const PAST_ACTIVITIES = ['cancelled', 'completed'];
const STATE_COLOR_MAP = {
	created: 'success',
	active: 'success',
	completed: 'success',
	error: 'error',
	cancelled: 'error',
};

/** Debug component is visible if on localhost, or if the user matches a
 * specific user id. This allows us to debug the activity in production
 */
const DebugData = ({ activity }) => {
	const environment = getEnvironment();
	const scheduleId = activity?.appData?.activityData?.scheduleId;
	return ['localhost', 'staging'].includes(environment) ? (
		<>
			<Typography
				variant="caption"
				textAlign="right"
				sx={{
					display: 'block',
					mt: 8,
					color: 'common.light',
					opacity: 0.5,
				}}
			>
				Debugging data
			</Typography>
			org:{' '}
			<a
				href={`http://localhost:5005/firestore/data/orgs/${activity.orgId}`}
				target="_blank"
				rel="noreferrer"
			>
				{activity.orgId}
			</a>
			<br />
			activity:{' '}
			<a
				href={`http://localhost:5005/firestore/data/orgs/${activity.orgId}/orgActivities/${activity.orgActivityId}`}
				target="_blank"
				rel="noreferrer"
			>
				{activity.orgActivityId}
			</a>
			<br />
			schedule:{' '}
			<a
				href={`http://localhost:5005/firestore/data/schedules/${scheduleId}`}
				target="_blank"
				rel="noreferrer"
			>
				{scheduleId}
			</a>
		</>
	) : (
		<></>
	);
};

const ActivityData = ({ activity, owner, schedule }) => {
	if (!activity) return <></>;
	log('activitySchedule', schedule);

	const activityData = {
		owner: owner?.displayName || '...',
		start: DateTime.fromISO(
			activity.appData.activityData.dtStart,
		).toLocaleString(DateTime.DATETIME_MED),
		timezone: activity.appData.activityData.timezone,
		nextRun: schedule
			? DateTime.fromMillis(schedule.nextRunMs).toLocaleString(
					DateTime.DATETIME_MED,
			  )
			: '...',
	};
	if (activity.appData.activityData.minutesOfHour) {
		activityData.schedule =
			activity.appData.activityData.minutesOfHour.join(', ');
	}
	if (activity.appData.activityData.daysOfWeek) {
		activityData.schedule = activity.appData.activityData.daysOfWeek
			.map((d) => textTools.capitalize(d))
			.join(', ');
	}
	if (activity.errorReason) {
		activityData['error reason'] = activity.errorReason;
	}

	return (
		<>
			<Typography variant="h4">
				{textTools.capitalize(activity.appData.chatId)} channel: #
				{activity.appData.chatChannelName}{' '}
				<Chip
					label={activity.state}
					color={STATE_COLOR_MAP[activity.state]}
					sx={{ ml: 4 }}
				/>
			</Typography>
			<Divider sx={{ my: 4 }} />
			<TableContainer>
				<Table size="small">
					<TableBody>
						{Object.entries(activityData).map(([key, value]) => (
							<TableRow key={key}>
								<TableCell>
									{textTools.capitalize(key)}
								</TableCell>
								<TableCell>
									<Typography>{value}</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

const CancelActivitySection = ({ activity }) => {
	const { cancelActivity } = useConnect();
	const [cancelOpen, setCancelOpen] = useState(false);
	const [cancel, triggerCancel] = useAsync(cancelActivity);

	const handleCancel = () => {
		log('cancel activity', activity);
		triggerCancel(
			activity.orgId,
			activity.orgActivityId,
			activity.appData.activityType,
		);
	};
	const showCancelWarning = () => {
		setCancelOpen(true);
	};
	const hideCancelWarning = () => {
		setCancelOpen(false);
	};

	return (
		<>
			<Dialog
				open={cancelOpen}
				onClose={hideCancelWarning}
				aria-labelledby="cancel-warning-title"
				aria-describedby="cancel-warning-description"
			>
				<DialogTitle id="cancel-warning-title">
					<Typography variant="h4">Cancel activity?</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="cancel-warning-description">
						<Typography variant="body1" sx={{ mb: 4 }}>
							Are you sure you want to cancel this activity?
						</Typography>
						<Typography variant="body1" sx={{ mb: 4 }}>
							This will stop all posts for this activity to your
							channel and this activity will no longer respond to
							user actions.
						</Typography>
						<Typography variant="caption">
							Your Slack channel will remain active.
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ p: 4 }}>
					<RsButton variant="text" onClick={hideCancelWarning}>
						Back
					</RsButton>
					<LoadingButton
						color="error"
						variant="outlined"
						loading={cancel.loading}
						onClick={handleCancel}
					>
						Continue
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Divider sx={{ my: 4 }} />
			<RsButton
				variant="outlined"
				color="error"
				onClick={showCancelWarning}
			>
				Cancel activity
			</RsButton>
			<Typography variant="caption" sx={{ ml: 4 }} color="error">
				End this activity and prevent it posting any future messages.
			</Typography>
		</>
	);
};

/** ManageActivity -
 * renders the ManageActivity component which shows a user the details for a
 * specific activity (by orgActivityId) and allows the user to cancel the
 * activity.
 *
 * Activity data is provided by the useConnect hook. We can use this hook to
 * retrieve the activity data we need by filtering the activities array by
 * orgActivityId.
 */
export const ManageActivity = () => {
	const { activityId } = useParams();
	const { activities } = useConnect();
	const { navigateTo } = useRsNavigate();
	const [activity, setActivity] = useState(null);
	const [activitySchedule, listenActivitySchedule] = useFirestoreDoc();
	const [activityOwner, listenActivityOwner] = useFirestoreDoc();

	log('activities', activities);

	useEffect(() => {
		setActivity(activities?.find((a) => a.orgActivityId === activityId));
	}, [activities, activityId]);

	useEffect(() => {
		let unsubSchedule = () => {};
		let unsubOwner = () => {};
		if (activity) {
			const scheduleId = activity?.appData?.activityData?.scheduleId;
			const { orgId, ownerId } = activity;
			if (scheduleId) {
				unsubSchedule = listenActivitySchedule([
					'schedules',
					scheduleId,
				]);
			}
			if (orgId && ownerId) {
				unsubOwner = listenActivityOwner([
					'orgs',
					orgId,
					'orgUsers',
					ownerId,
				]);
			}
		}
		return () => {
			unsubSchedule();
			unsubOwner();
		};
	}, [activity, listenActivityOwner, listenActivitySchedule]);

	useEffect(() => {
		log('schedule', activitySchedule.data);
	}, [activitySchedule]);

	const handleBack = () => {
		navigateTo('/connect');
	};
	const BackButton = () => {
		return (
			<>
				<RsButton variant="text" onClick={handleBack}>
					Back
				</RsButton>
			</>
		);
	};
	return activity ? (
		<>
			<ContentHeader
				title={textTools.capitalize(activity.appData.activityType)}
				menu={<BackButton />}
				// content={}
			/>
			<Grid container spacing={4}>
				<Grid xxs={12}>
					{activity.state === 'created' && (
						<>
							<Typography
								variant="h3"
								textAlign="center"
								sx={{ mb: 4 }}
							>
								🎉 Activity Created
							</Typography>
							<img
								src="https://cdn.remotesocial.io/common/img/gifs/success.webp"
								alt="Success"
								style={{
									height: '200px',
									margin: '0 auto 1rem',
									display: 'block',
									borderRadius: '1rem',
								}}
							/>
							<Container maxWidth="xs" sx={{ my: 10 }}>
								<Typography variant="h4" sx={{ mb: 4 }}>
									Next steps
								</Typography>
								<hr />
								<ul>
									<li>
										Invite your team to join the{' '}
										<b>
											#{activity.appData.chatChannelName}
										</b>{' '}
										channel
									</li>
									<li>
										Review the details for your activity
										below
									</li>
									{/* <li>
										Consider adding the{' '}
										<Link to="/connect/gratituesday">
											Gratituesday
										</Link>{' '}
										activity
									</li> */}
								</ul>
							</Container>
						</>
					)}
				</Grid>
				<Grid xxs={12}>
					<Container maxWidth="xs">
						<Card variant="outlined">
							<CardMedia>
								<img
									src="https://cdn.remotesocial.io/rs2/connect/img/poster-wordle.png"
									alt="Wordle"
									height="120px"
									width="100%"
									style={{ objectFit: 'cover' }}
								/>
							</CardMedia>
							<CardContent>
								<ActivityData
									activity={activity}
									owner={activityOwner.data}
									schedule={activitySchedule.data}
								/>
							</CardContent>
						</Card>
					</Container>
				</Grid>
			</Grid>
			<Container>
				{activity && PAST_ACTIVITIES.includes(activity.state) ? (
					<></>
				) : (
					<CancelActivitySection activity={activity} />
				)}
				<Divider sx={{ my: 4 }} />
				<DebugData activity={activity} />
			</Container>
		</>
	) : (
		<Loading />
	);
};
