import { useEffect, useRef } from 'react';
import { functions, httpsCallable } from '@common/firebase';
import { useChatApp, useCurrentUser, useFirestoreQuery } from '@common/hooks';

const log = (...args) => window.log('[app][useConnect]', ...args);
const error = (...args) => console.error('Error: [app][useConnect]', ...args);

/**
 * returns a number of convenience functions that can be used during connect
 * activity creation and management
 */
export const useConnect = () => {
	const { user } = useCurrentUser();
	const { team } = useChatApp();
	const [{ data }, listenActivities] = useFirestoreQuery();
	const unsubscribeActivities = useRef(() => {});

	useEffect(() => {
		return unsubscribeActivities.current();
	}, []);

	useEffect(() => {
		if (user?.currentOrg?.orgId) {
			// attempt to fetch connect activities
			const path = `orgs/${user.currentOrg.orgId}/orgActivities`;
			const query = ['appName', '==', 'connect'];
			unsubscribeActivities.current = listenActivities(path, query);
		}
	}, [user, listenActivities]);

	/**
	 * reusable function to start a "create activity" action for connect
	 * on the server
	 * @param {object} activityData - the data to use to create the activity
	 * @return {string} the id of the newly created activity
	 */
	const createActivity = async (activityType, activityData) => {
		log('createActivity - activityType:', activityType);

		let createActivityCallable;

		switch (activityType) {
			case 'wordle':
				/** create the callable function to trigger async */
				createActivityCallable = httpsCallable(
					functions,
					'appConnect-activities-wordle-createActivity',
				);
				break;
			case 'gratituesday':
				createActivityCallable = httpsCallable(
					functions,
					'appConnect-activities-gratituesday-createActivity',
				);
				break;
			default:
				throw new Error(`Unknown activityType: ${activityType}`);
		}

		/** throw an error if required data isn't loaded */
		try {
			if (!user) throw new Error('Missing currentUser');
			if (!user.currentOrg) throw new Error('Missing user.currentOrg');
			if (!team) throw new Error('Missing messagingApp team');
		} catch (e) {
			error('createActivity -', e.message, e);
			throw e;
		}

		/** compile the required data together to send to the server */
		const data = {
			orgId: user.currentOrg.id, // from useCurrentUser
			ownerId: user.uid, // from useCurrentUser
			appName: 'connect',
			chatId: team.chatId, // from useChatApp
			teamId: team.id, // from useChatApp
			activityType, // passed in from the calling component
			...activityData,
		};

		/** trigger creation on the server and return the response */
		const { data: response } = await createActivityCallable(data);
		return response;
	};

	const cancelActivity = async (orgId, orgActivityId, activityType) => {
		log('UC: cancelActivity', { orgId, orgActivityId, activityType });
		let cancelActivityCallable;
		switch (activityType) {
			case 'wordle':
				cancelActivityCallable = httpsCallable(
					functions,
					'appConnect-activities-wordle-cancelActivity',
				);
				break;
			case 'gratituesday':
				cancelActivityCallable = httpsCallable(
					functions,
					'appConnect-activities-gratituesday-cancelActivity',
				);
				break;
			default:
				throw new Error(`Unknown activityType: ${activityType}`);
		}

		const { data: response } = await cancelActivityCallable({
			orgId,
			orgActivityId,
		});
		return response;
	};

	return {
		createActivity,
		cancelActivity,
		activities: data || [],
	};
};
