import { useMemo } from 'react';
import { DaysOfWeek } from '@common/components/forms';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { Field } from 'formik';
import { DateTime as DT, Info } from 'luxon';
import Joi from 'joi';

const labels = {
	daily: 'Select times to post',
	weekly: 'Select days to post',
	monthly: 'Select days to post',
	yearly: 'Select days to post',
};

/** useSelectFrequency
 * A custom hook that returns a step configuration object.
 *
 * During Connect Activity creation the users is asked to choose a schedule that
 * they want the activity to run. This may include hourly, daily schedules,
 * weekly, monthly, or even yearly.
 *
 * This step requires some config params to be passed in to determine the type
 * of schedule that is being created. The config params are:
 * - repeating frequency (hourly, daily, weekly, monthly, yearly)*
 * - repeating constraints (days to omit from options, etc...)?
 *
 * @param {String} type - 'repeating' or 'once'
 * @param {Object} [config] - config options for the schedule
 * @param {String} config.label - the label for the step
 * @param {String} config.frequency - 'hourly', 'daily', 'weekly', 'monthly', 'yearly'
 * @param {Object} config.frequencyConfig - additional configuration for the frequency (days to omit, starting values);
 * @param {Object} config.initialValues - any initial values for the step
 * @return {Object} - a step object for use in the RsWizard component
 * @return {String} - step.label - the label for the step
 * @return {Object} - step.initialValues - the initial values for the step
 * @return {Object} - step.validate - the validation schema for the step
 * @return {React.Component} - step.Component - the component for the step
 */

export const useSelectFrequency = (options) => {
	return useMemo(
		() => ({
			label: labels[options.repeat],
			initialValues: {
				daysOfWeek: [DT.now().weekdayShort.toLowerCase()],
				...options.initialValues,
			},
			validate: {
				/** Date field always publishes a milliseconds timestamp */
				daysOfWeek: Joi.array()
					.items(
						Joi.string()
							.required()
							.valid(
								...Info.weekdays('short').map((day) =>
									day.toLowerCase(),
								),
							),
					)
					.min(1)
					.max(7)
					.required()
					.messages({
						'array.base':
							'Please select at least one valid day of the week',
						'any.required':
							'Please select at least one valid day of the week',
						'array.min':
							'Please select at least one valid day of the week',
						'array.max': 'Please select at most 7 days of the week',
					}),
			},
			Component: ({
				setFieldValue,
				setFieldTouched,
				errors,
				touched,
				...props
			}) => {
				const handleChangeDays = (value) => {
					setFieldTouched('daysOfWeek', true, false);
					setFieldValue('daysOfWeek', value);
				};
				return (
					<Grid container xxs={12} spacing={4}>
						<Grid xxs={12}>
							{options.preamble && (
								<Typography sx={{ mb: 6 }}>
									{options.preamble}
								</Typography>
							)}
							<Field
								component={DaysOfWeek}
								name="daysOfWeek"
								label="Select days of the week"
								intro="Days to post"
								caption="We recommend at least 2 days of the week"
								error={
									touched?.daysOfWeek && !!errors?.daysOfWeek
								}
								helperText={
									touched?.daysOfWeek && errors?.daysOfWeek
								}
								onChange={handleChangeDays}
								sx={{ mb: 8 }}
							/>
						</Grid>
					</Grid>
				);
			},
		}),
		[options],
	);
};
