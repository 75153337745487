import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalHeader, GlobalFooter, DocumentHead } from '@common/components';
import { DashboardMenu, DashboardDrawer } from '@app/components/DashboardMenu';
import {
	Box,
	Container,
	Paper,
	useMediaQuery,
	Toolbar,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';

let handleDrawerToggle = () => {};
let handleDrawerClose = () => {};

/** mobile layout */
const TabletLayout = ({ children, drawerOpen }) => {
	const theme = useTheme();
	const { makeTransparent } = theme.palette;
	useEffect(() => {
		return () => handleDrawerClose();
	}, []);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<DashboardDrawer
				isTablet={true}
				drawerOpen={drawerOpen}
				handleDrawerClose={handleDrawerClose}
			/>
			<Container>
				<Paper
					variant="outlined"
					sx={{
						overflow: 'hidden',
						p: 6,
						minHeight: 'calc(100vh - 13rem - 7rem)',
						color: 'primary.surfaceText',
						mb: 8,
						background: makeTransparent(
							theme.palette.primary.surface,
							0.85,
						),
					}}
				>
					{children ? children : <Outlet />}
				</Paper>
			</Container>
		</Box>
	);
};

/** desktop layout */
const WideLayout = ({ children }) => {
	const theme = useTheme();
	const { makeTransparent } = theme.palette;
	return (
		<Box sx={{ p: theme.spacing(0, 4, 8, 4) }}>
			<Container
				maxWidth="xl"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
				}}
			>
				<Paper
					variant="outlined"
					sx={{
						width: '14rem',
						minWidth: '14rem',
						overflow: 'hidden',
						mr: 3,
						background: makeTransparent(
							theme.palette.primary.surface,
							0.85,
						),
					}}
				>
					<DashboardMenu variant="dark" />
				</Paper>
				<Paper
					variant="outlined"
					sx={{
						flexGrow: 1,
						alignSelf: 'stretch',
						overflow: 'hidden',
						ml: 3,
						p: 6,
						minHeight: 'calc(100vh - 13rem - 7rem)',
						// invert the text from the theme
						color: 'primary.surfaceText',
						background: makeTransparent(
							theme.palette.primary.surface,
							0.85,
						),
					}}
				>
					{children ? children : <Outlet />}
				</Paper>
			</Container>
		</Box>
	);
};

/** layout switcher */
export const DashboardLayout2 = ({ children }) => {
	const theme = useTheme();
	const isTabletOrLower = useMediaQuery(theme.breakpoints.down('md'));
	const [drawerOpen, setDrawerOpen] = useState(false);
	/** set global function handlers to work with this drawerOpen state */
	handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};
	handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	/** blend mode for colour gradients */
	const headerBlendMode = 'multiply';
	// const textureUrl =
	// 	'https://cdn.remotesocial.io/common/img/textures/bright-squares.png';
	/** compiled styles to be applied to the header */
	const headerBackgroundStyle = {
		// backgroundImage: [`url(${textureUrl})`].join(','),
		backgroundBlendMode: headerBlendMode,
	};

	return (
		<>
			<GlobalHeader
				drawerButton={isTabletOrLower}
				handleDrawerToggle={handleDrawerToggle}
			/>
			<Box
				sx={{
					...headerBackgroundStyle,
					color: 'primary.surface',
				}}
			>
				{isTabletOrLower ? (
					<TabletLayout children={children} drawerOpen={drawerOpen} />
				) : (
					<WideLayout children={children} />
				)}
			</Box>
			<GlobalFooter />
		</>
	);
};

export const ContentHeader = ({ title, menu, content }) => {
	return (
		<>
			<DocumentHead title={title} />
			<Box sx={{ mb: 8 }}>
				<Toolbar disableGutters sx={{ alignItems: 'center', mb: 4 }}>
					<Typography
						variant="h1"
						color="inherit"
						sx={{
							m: 0,
							mr: 4,
							letterSpacing: -1,
							flexGrow: 1,
						}}
					>
						{title}
					</Typography>
					{menu}
				</Toolbar>
				<Grid container>
					<Grid sm={11} md={10} lg={10} xl={8}>
						{content}
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

// export const SectionHeader2 = ({ title, menu, textureUrl, sx }) => {
// 	const theme = useTheme();

// 	/** pink-ish orange background gradient */
// 	const headerBackgroundGradient = [
// 		`linear-gradient(20deg, ${theme.palette.rsPurple.main}, transparent)`,
// 		`linear-gradient(170deg, ${theme.palette.rsBlue.dark}, transparent)`,
// 		`linear-gradient(210deg, ${theme.palette.rsPink.main}, transparent)`,
// 	].join(',');
// 	/** fade the header to white at the lower edge */
// 	const headerBackgroundFade = `linear-gradient(0deg, ${theme.palette.primary.surface} 0%, transparent 8%)`;
// 	/** blend mode for colour gradients */
// 	const headerBlendMode = 'lighten';
// 	/** compiled styles to be applied to the header */
// 	const headerBackgroundStyle = {
// 		backgroundImage: [
// 			headerBackgroundGradient,
// 			`url(${textureUrl})`,
// 			headerBackgroundFade,
// 		].join(','),
// 		backgroundBlendMode: headerBlendMode,
// 	};

// 	return (
// 		<h1>header</h1>
// 		// <GlobalHeader
// 		// 	drawerButton={isMobile}
// 		// 	handleDrawerToggle={handleDrawerToggle}
// 		// />
// 	);
// };

// export const SectionContent2 = ({ children }) => {
// 	return (
// 		<Box
// 			id="SectionContent"
// 			sx={{
// 				mx: { xs: 4, sm: 6, md: 10, lg: 12, xl: 16 },
// 				my: 8,
// 				minHeight: 'calc(100vh - 13rem - 7rem)',
// 			}}
// 		>
// 			<Container>{children}</Container>
// 		</Box>
// 	);
// };
