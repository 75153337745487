import { useState, useEffect, useRef, useMemo } from 'react';
import { FullScreenLayout } from '@app/layouts';

import { useTheme } from '@mui/material/styles';
import { Paper, Container } from '@mui/material';

import { RsWizard, DocumentHead } from '@common/components';
import { getParamByNameFromUrl } from '@common/utils';
import {
	useCurrentUser,
	useOrgs,
	useRsNavigate,
	useAsync,
} from '@common/hooks';

import { Intro } from './CreateOrg_00';
import { CreateOrg_01 } from './createOrg_01';
import { CreateOrg_02 } from './createOrg_02';
import { CreateOrg_03 } from './createOrg_03';
import { orgConfigOptions } from '../../appConfig';

import Joi from 'joi';

/**
 * React Component to create a new org on Remote Social
 *
 * We use the RsWizard component to create a multi-step form.
 *
 * Submit the data to the server to create a new org and store the returned orgId
 *
 * When the currentUser object is updated with the new orgId,
 * we redirect to the dashboard for the new org.
 */
export const CreateOrg = () => {
	const theme = useTheme();
	const { user } = useCurrentUser();
	const { createOrg, setNewCurrentOrg } = useOrgs();
	const { navigateToApp } = useRsNavigate();
	const [createOrgState, triggerCreateOrg] = useAsync(createOrg);
	const formikBagRef = useRef(null); // container for formikBag
	const timeoutRef = useRef(null); // container for the setTimeout ref

	// show or hide the createOrg intro slide
	const [showIntro, setShowIntro] = useState(
		getParamByNameFromUrl('showIntro') === 'true',
	);
	const handleHideIntro = () => setShowIntro(false);
	const handleShowIntro = () => setShowIntro(true);

	/**
	 * callback to cancel/reset form data
	 * @param {Object} values - form values
	 * @param {FormikBag} formikBag
	 * @return {Promise}
	 */
	const onReset = (values, formikBag) => {
		handleShowIntro();
	};

	/**
	 * callback to submit form data once the form is complete
	 * will update serverErrors if there is an error returned from the server
	 * will forward the user along to the new org dashboard if there is no error
	 * @param {Object} values - form values
	 * @param {FormikBag} formikBag
	 * @return {Promise}
	 */
	const onSubmit = async (values, formikBag) => {
		log('Submit form:', values, formikBag);
		formikBagRef.current = formikBag;
		/** set the inProgress state on the form */
		formikBag.setStatus({ state: 'inProgress', message: 'submitting' });
		/** trigger the createOrg async function */
		await triggerCreateOrg(values);
	};

	/**
	 * here we compare the returned orgId value from org creation process
	 * with the currentOrgId value from the currentUser object. If they match
	 * then the org has been created and the user object updated. We can proceed
	 * the user to the dashboard.
	 * */
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		const { loading, error, response } = createOrgState;
		const currentOrgId = user?.currentOrg?.id;

		log('📝 createOrg: useEffect', {
			'user.currentOrg.id': currentOrgId,
			'createdOrgId': response,
		});

		/** short circuit if createdOrgState is still loading */
		if (loading) {
			log('📝 createOrg: user or createOrgState is loading...');
			return;
		}

		/** handle if an error was returned from the server */
		if (error) {
			const error = createOrgState.error;
			const formikBag = formikBagRef.current;
			// handle error here.
			console.error('Error creating org:', error.message);
			if (formikBag) {
				formikBag.setErrors(error.details);
				formikBag.setStatus({ state: 'error', message: error.message });
			}
		}

		if (response && currentOrgId) {
			log('response', response, 'user', user);
			if (response === currentOrgId) {
				/**
				 * the server has responded with an orgId and the orgId is now
				 * showing as the currentOrgId value on the user object.
				 */
				/** remove the submitting status on the form */
				formikBagRef.current &&
					formikBagRef.current.setSubmitting(false);
				/** navigate to the dashboard */
				navigateToApp('app', '/');
				return;
			}
			/**
			 * the sever has responded with an orgId, but the currentOrg
			 * has not yet been updated, let's set a timeout for a few seconds,
			 * and if the value is still not updated, we can trigger setting
			 * the org value manually here.
			 */
			timeoutRef.current = setTimeout(() => {
				log('timeout elapsed, calling setNewCurrentOrg', response);
				setNewCurrentOrg(response);
			}, 5000); // 10 seconds delay
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, createOrgState]);

	const checkDomainAgainstBlacklist = (value, helpers) => {
		const domain = value.split('.')[0];
		if (orgConfigOptions.domainBlacklist.includes(domain)) {
			throw new Error('Domain is not allowed');
		}
		return value;
	};

	const steps = useMemo(() => {
		const step_01 = {
			label: 'Org Name & website',
			Component: CreateOrg_01,
			initialValues: {
				name: user.email.split('@')[1].split('.')[0],
				domain: user.email.split('@')[1],
			},
			validate: {
				name: Joi.string()
					.required()
					.min(3)
					.max(80)
					.pattern(/^[a-zA-Z0-9_-\s]+$/)
					.messages({
						'string.empty': 'Org Name is required',
						'string.base': 'Org Name must be text',
						'string.min':
							'Org Name must be at least {#limit} characters',
						'string.max':
							'Org Name must me at most {#limit} characters',
						'string.pattern.base':
							'Org Name must be letters, numbers, spaces, underscores, and dashes only',
					}),
				domain: Joi.string()
					.required()
					.lowercase()
					.trim()
					.replace(/https?:\/\//i, '')
					.replace(/www\./i, '')
					.custom(
						checkDomainAgainstBlacklist,
						'check against blacklist',
					)
					.domain()
					.messages({
						'string.base': 'Org domain must be text',
						'string.empty': 'Org domain is required',
						'any.custom': 'That domain is not allowed',
						'string.domain':
							'Org domain must be a valid domain name',
					}),
				domain2: Joi.string(),
			},
		};
		const step_02 = {
			label: 'Org Size & Industry',
			Component: CreateOrg_02,
			initialValues: {
				size: 0, // company size
				industry: 'Not specified',
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			},
			validate: {
				size: Joi.number().min(0).max(4).integer().messages({
					'number.base': 'Use the slider to select org size',
					'number.min': 'Organization size must be between 0 and 4',
					'number.max': 'Organization size must be between 0 and 4',
					'number.integer':
						'Organization size must be a whole number',
				}),
				industry: Joi.string()
					.valid(...orgConfigOptions.industries)
					.messages({
						'string.base': 'Org industry must be text',
						'any.only': 'Org industry must be from the list',
					}),
				timezone: Joi.string()
					.valid(...orgConfigOptions.timezones)
					.messages({
						'string.base': 'Org timezone must be text',
						'any.only':
							'Org timezone must be from the timezones list',
					}),
			},
		};
		const step_03 = {
			label: 'More about you',
			Component: CreateOrg_03,
			initialValues: {
				department: 'Not specified', // owner team/department
				jobTitle: '', // owner job title
				displayName: user.displayName,
			},
			validate: {
				department: Joi.string()
					.valid(...orgConfigOptions.departments)
					.messages({
						'string.base': 'Department must be text',
						'any.only':
							'Department must be one of the provided options',
					}),
				jobTitle: Joi.string()
					.min(3)
					.messages({
						'string.base': 'Job title must be text',
						'string.min': 'Job title must be at least 3 characters',
					})
					.allow(''),
				displayName: Joi.string().min(3).allow('').messages({
					'string.base': 'Display name must be text',
					'string.min': 'Display name must be at least 3 characters',
				}),
			},
		};

		return [step_01, step_02, step_03];
	}, [user.displayName, user.email]);

	log('create');
	return (
		<FullScreenLayout>
			<DocumentHead title="Create Organisation" />
			<Container fixed maxWidth="md">
				<Paper
					elevation={5}
					sx={{
						p: 4,
						minHeight: '20rem',
						background: theme.palette.primary.surface,
						color: theme.palette.primary.surfaceText,
					}}
				>
					{showIntro ? (
						<Intro hideIntro={handleHideIntro} />
					) : (
						<RsWizard
							title="Create a new org" // form title
							steps={steps} // create org form steps
							formikConfig={{
								validateOnBlur: true, // do we validate every field blur?
								validateOnChange: true, // do we validate every field change?
								validateOnMount: false, // do we validate when we mount the entire form?
								onSubmit: onSubmit, // submit callback
								onReset: onReset, // reset callback
							}}
						/>
					)}
				</Paper>
			</Container>
		</FullScreenLayout>
	);
};
